<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.46967 1.21967C2.76256 0.926777 3.23744 0.926777 3.53033 1.21967L14.5303 12.2197C14.8232 12.5126 14.8232 12.9874 14.5303 13.2803C14.2374 13.5732 13.7626 13.5732 13.4697 13.2803L2.46967 2.28033C2.17678 1.98744 2.17678 1.51256 2.46967 1.21967Z"
    />
    <path
      d="M12.007 8.03495C12.0837 7.92732 12.1581 7.81773 12.2276 7.70831C13.117 6.30952 13.5967 4.68788 13.5897 2.98802C13.5884 2.71948 13.3703 2.50146 13.1018 2.50007C11.4033 2.49315 9.78098 2.9721 8.3822 3.86148C8.26998 3.93283 8.1602 4.00694 8.05407 4.08199L12.007 8.03495Z"
    />
    <path
      d="M5.46905 6.89623C4.89415 7.89796 4.5257 9.00502 4.38183 10.1638L0.85477 11.1716C0.654055 11.2283 0.512862 11.4041 0.501096 11.6111C0.487254 11.8173 0.605606 12.009 0.796632 12.0921L3.03842 13.0521L3.99909 15.2946C4.0247 15.3534 4.0593 15.4046 4.10291 15.4482C4.2005 15.5458 4.33685 15.5991 4.48012 15.5901C4.68637 15.5776 4.86286 15.4357 4.91961 15.2364L5.92666 11.7087C7.08547 11.5648 8.19253 11.1963 9.19426 10.6214L5.46905 6.89623Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
