import axios from 'axios';
import { UiDictionariesClient } from '~/app-modules/core/clients/ui-dictionaries-client';
import { StrictMap } from '~/app-modules/core/utils/strict-map';
import { UiDictionaryType } from '~/app-modules/core/enums/UiDictionaryType';
import { localUIDictionaries } from '~/app-modules/core/data/localUIDictionaries.data';

const useLocalizationsStore = defineStore('LocalizationsStore', () => {
  const uiDictionariesClient = useIOC(UiDictionariesClient);
  const dictionaries = shallowReactive(
    new StrictMap<UiDictionaryType, StrictMap<string, string>>()
  );

  // Метод для не ssr страниц и для совместимости, будет удален
  async function getDictionaryByType(type: UiDictionaryType): Promise<StrictMap<string, string>> {
    if (dictionaries.size !== 0) return dictionaries.strictGet(type);

    await loadDictionaries();
    return dictionaries.strictGet(type);
  }

  function getDictionaryByTypeSync(type: UiDictionaryType): StrictMap<string, string> {
    return dictionaries.strictGet(type);
  }

  async function loadDictionaries() {
    try {
      for (const [type, dict] of localUIDictionaries) {
        dictionaries.set(type as UiDictionaryType, dict);
      }

      const uiDictionaries = await uiDictionariesClient.getUiDictionaries();

      for (const [type, dict] of Object.entries(uiDictionaries)) {
        dictionaries.set(type as UiDictionaryType, new StrictMap<string, string>(dict));
      }
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }
    }
  }

  // Вызывать в nuxt плагине (что гарантирует инизиализацию стора до его использования)
  async function initStore() {
    // client side init
    if (dictionaries.size !== 0) {
      // strict maps need to init from maps
      for (const [type, dict] of dictionaries) {
        dictionaries.set(type, new StrictMap<string, string>(dict));
      }

      return;
    }

    // server side init
    await loadDictionaries();
  }

  return {
    dictionaries,
    getDictionaryByType,
    getDictionaryByTypeSync,
    loadDictionaries,
    initStore,
  };
});

// @injectable()
// export class LocalizationsStoreProvider extends StoreProvider(useLocalizationsStore) {}

@injectable()
export class LocalizationsStoreProvider {
  getStore = () => useLocalizationsStore();
}
