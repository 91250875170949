import { type AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { z } from 'zod';
import { NotAuthorizedClient } from './not-authorized-client';
import { HttpConnection, RequestRetryLimitExceededException } from './http-connection';
import { AuthService } from '~/app-modules/auth/services/auth.service';
import { NotLoggedIn } from '~/app-modules/core/errors/not-logged-in';

export class AuthorizedClient extends NotAuthorizedClient {
  constructor(httpConnection: HttpConnection, private authService: AuthService) {
    super(httpConnection);
  }

  protected async executeRequest<T>(
    options: AxiosRequestConfig,
    deserializer: z.Schema<T> = z.optional(z.any())
  ): Promise<T> {
    try {
      return await this._executeRequest(options, deserializer, false);
    } catch (e) {
      if (e instanceof RequestRetryLimitExceededException) {
        // Обновление токена невозможно, требуется выполнить logout и перезагрузку страницы
        this.authService.logout();
        throw reloadNuxtApp();
      }

      throw e;
    }
  }

  protected async executeRequestAllowAnonymous<T>(
    options: AxiosRequestConfig,
    deserializer: z.Schema<T> = z.optional(z.any())
  ): Promise<T> {
    try {
      return await this._executeRequest(options, deserializer, true);
    } catch (e) {
      if (e instanceof RequestRetryLimitExceededException) {
        // Обновление токена невозможно, требуется выполнить logout и перезагрузку страницы
        this.authService.logout();
        throw reloadNuxtApp();
      }

      throw e;
    }
  }

  private async _executeRequest<T>(
    options: AxiosRequestConfig,
    deserializer?: z.Schema<T>,
    allowAnonymous: boolean = false
  ): Promise<T> {
    if (!allowAnonymous && !this.authService.isLoggedIn) {
      showError(new NotLoggedIn());
    }

    let tryCount = 1;

    while (tryCount <= 2) {
      try {
        const reqOptions: AxiosRequestConfig = this.authService.isLoggedIn
          ? { ...options, headers: { Authorization: `${this.authService.Bearer}` } }
          : { ...options };
        return await super.executeRequest(reqOptions, deserializer);
      } catch (e) {
        if (this.isUnAuthorizedException(e)) {
          await this.authService.ensureTokensRefreshed();
          tryCount++;
          continue;
        }
        throw e;
      }
    }
    throw new RequestRetryLimitExceededException();
  }

  private isUnAuthorizedException(e: unknown) {
    return axios.isAxiosError(e) && e.response?.status === 401;
  }
}
