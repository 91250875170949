import { defineNuxtPlugin } from '#app';
import mitt, { Emitter } from 'mitt';
import { EventBusEventType } from '~/app-modules/core/enums/event-bus.event.type';

const emitter = mitt();

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('eventBus', emitter);
});

export type eventBus = Emitter<Record<EventBusEventType, unknown>>;

interface PluginsInjections {
  $eventBus: eventBus;
}

declare module '#app' {
  interface NuxtApp extends PluginsInjections {}
}
