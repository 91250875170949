import { z } from 'zod';

export const UserDtoSchema = z.object({
  userId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  middleName: z.string().nullable(),
  phone: z.string().optional(),
  email: z.string().optional(),
  organizationId: z.optional(z.string()),
  organizationName: z.optional(z.string()),
});

export type UserDto = z.infer<typeof UserDtoSchema>;
