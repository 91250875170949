import { TokensStoreProvider } from '~/app-modules/auth/stores/tokens.store';
import { UsersStoreProvider } from '~/app-modules/users/stores/users.store';
import { LocalizationsStoreProvider } from '~/app-modules/core/stores/localizations.store';
import { MetricsService } from '~/app-modules/metrics/services/metrics.service';
import { CartStoreProvider } from '~/app-modules/cart/stores/cart.store';
import { OrganizationsStoreProvider } from '~/app-modules/organizations/stores/organizations.store';

// Плагин для инициализации хранилищ данных и IOC зависимостей, вызывается дважды на сервере и клиенте,
// для корректной гидратации на ssr необходимо учитывать передаваемые типы данных и исключать повторную загрузку
//
export default defineNuxtPlugin(async (_nuxtApp) => {
  useIOC(TokensStoreProvider).getStore();
  useIOC(CartStoreProvider).getStore();

  const localizationsStore = useIOC(LocalizationsStoreProvider).getStore();
  await localizationsStore.initStore();

  const usersStore = useIOC(UsersStoreProvider).getStore();
  await usersStore.initStore();

  const organizationsStore = useIOC(OrganizationsStoreProvider).getStore();
  await organizationsStore.initStore();

  if (process.client) {
    useIOC(MetricsService);
  }
});
