<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1075 13.25V8.5C15.1075 4.56497 11.9176 1.375 7.98254 1.375C4.04752 1.375 0.857544 4.56497 0.857544 8.5V13.25M2.83671 15.625C1.74365 15.625 0.857544 14.7389 0.857544 13.6458V12.0625C0.857544 10.9694 1.74365 10.0833 2.83671 10.0833C3.92977 10.0833 4.81588 10.9694 4.81588 12.0625V13.6458C4.81588 14.7389 3.92977 15.625 2.83671 15.625ZM13.1284 15.625C12.0353 15.625 11.1492 14.7389 11.1492 13.6458V12.0625C11.1492 10.9694 12.0353 10.0833 13.1284 10.0833C14.2214 10.0833 15.1075 10.9694 15.1075 12.0625V13.6458C15.1075 14.7389 14.2214 15.625 13.1284 15.625Z"
      fill="none"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
