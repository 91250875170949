<template>
  <svg
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="m23.263,13.885c.981.982.981,2.581,0,3.562l-5.37,5.37c-.787.787-1.821,1.18-2.855,1.18s-2.067-.393-2.854-1.18c-.763-.762-1.183-1.776-1.183-2.855s.42-2.092,1.183-2.855l4.213-4.212c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414l-4.213,4.212c-.385.385-.597.897-.597,1.441s.212,1.056.597,1.441c.796.794,2.088.793,2.882,0l5.37-5.37c.202-.202.202-.532,0-.734-.195-.195-.537-.196-.734,0l-4.407,4.407c-.391.391-1.023.391-1.414,0s-.391-1.023,0-1.414l4.407-4.407c.951-.95,2.609-.952,3.562,0Zm.737-8.885v5c0,.552-.447,1-1,1s-1-.448-1-1v-5c0-.281-.051-.547-.124-.805l-6.341,6.342c-.945.944-2.201,1.464-3.536,1.464s-2.591-.52-3.536-1.464L2.124,4.195c-.073.258-.124.525-.124.805v9c0,1.654,1.346,3,3,3h3c.553,0,1,.448,1,1s-.447,1-1,1h-3c-2.757,0-5-2.243-5-5V5C0,2.243,2.243,0,5,0h14c2.757,0,5,2.243,5,5Zm-3.295-2.463c-.485-.337-1.071-.537-1.705-.537H5c-.634,0-1.22.2-1.705.537l6.584,6.585c.567.566,1.344.849,2.122.85.777,0,1.555-.283,2.122-.85l6.584-6.585Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
