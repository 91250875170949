<template>
  <svg
    width="21"
    height="15"
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.511 2.68813C8.00906 2.64963 6.9002 4.39908 6.46076 5.71739L6.22905 6.41252L5.49854 6.35559C4.57458 6.2836 3.77598 6.52507 3.20903 6.98625C2.65387 7.43785 2.24442 8.16109 2.19268 9.21774C2.13297 10.4372 2.59241 11.2467 3.2417 11.7756C3.92358 12.3311 4.86937 12.6156 5.77352 12.6156H15.7708C16.2805 12.6156 17.0315 12.4725 17.623 12.0932C18.1654 11.7455 18.5886 11.1999 18.5886 10.2448C18.5886 9.48845 18.2011 8.96143 17.6116 8.58323C16.9913 8.18529 16.233 8.01949 15.7782 8.0231L14.8333 8.0306V7.08563C14.8333 6.98228 14.807 6.68162 14.7467 6.3036C14.6872 5.9308 14.6079 5.56901 14.5273 5.33589C14.5221 5.3211 14.5169 5.306 14.5116 5.29061C14.3753 4.89516 14.1723 4.30644 13.6535 3.77941C13.1414 3.25923 12.231 2.71459 10.511 2.68813ZM4.93495 4.47346C5.6612 2.84023 7.29549 0.76344 10.5398 0.813362C12.6941 0.846513 14.0935 1.55366 14.9897 2.46409C15.8134 3.30084 16.1336 4.23837 16.2769 4.65777C16.2848 4.68099 16.2922 4.70262 16.2991 4.72256C16.433 5.10936 16.5326 5.59695 16.5982 6.00795C16.61 6.08179 16.6211 6.15601 16.6315 6.22957C17.2739 6.34749 17.9886 6.59738 18.6241 7.00511C19.5993 7.63077 20.4636 8.68832 20.4636 10.2448C20.4636 11.9125 19.6499 13.021 18.635 13.6717C17.6693 14.2909 16.5426 14.4905 15.7708 14.4905H5.77352C4.51903 14.4905 3.13179 14.1044 2.05751 13.2293C0.950643 12.3277 0.230799 10.9466 0.319939 9.12605C0.395069 7.59161 1.01784 6.35169 2.02585 5.53173C2.84338 4.8667 3.86248 4.51949 4.93495 4.47346Z"
      fill="#23A0E1"
      fill-opacity="0.8"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
