<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.5 3.4375C2.32741 3.4375 2.1875 3.57741 2.1875 3.75V11.25C2.1875 11.4226 2.32741 11.5625 2.5 11.5625H4.07886L5.9375 13.0494V11.5625H13.125C13.2976 11.5625 13.4375 11.4226 13.4375 11.25V3.75C13.4375 3.57741 13.2976 3.4375 13.125 3.4375H2.5ZM0.3125 3.75C0.3125 2.54188 1.29188 1.5625 2.5 1.5625H13.125C14.3331 1.5625 15.3125 2.54188 15.3125 3.75V7.1875H17.5C18.7081 7.1875 19.6875 8.16688 19.6875 9.375V14.375C19.6875 15.5831 18.7081 16.5625 17.5 16.5625H15.9539L13.4918 18.5321C12.7143 19.1541 11.5625 18.6006 11.5625 17.6048V16.5625H11.25C10.0419 16.5625 9.0625 15.5831 9.0625 14.375V13.4375H7.8125V14.4798C7.8125 15.4756 6.6607 16.0291 5.88317 15.4071L3.42114 13.4375H2.5C1.29188 13.4375 0.3125 12.4581 0.3125 11.25V3.75ZM15.3125 11.25V9.0625H17.5C17.6726 9.0625 17.8125 9.20241 17.8125 9.375V14.375C17.8125 14.5476 17.6726 14.6875 17.5 14.6875H15.2961L13.4375 16.1744V14.6875H11.25C11.0774 14.6875 10.9375 14.5476 10.9375 14.375V13.4375H13.125C14.3331 13.4375 15.3125 12.4581 15.3125 11.25ZM10.625 6.60041C10.9911 6.23429 10.9911 5.6407 10.625 5.27458C10.2589 4.90847 9.66529 4.90847 9.29917 5.27458L7.11167 7.74744L5.89959 6.53535C5.53347 6.16924 4.93988 6.16924 4.57376 6.53535C4.20765 6.90147 4.20765 7.49506 4.57376 7.86118L6.44876 9.73618C6.81488 10.1023 7.40847 10.1023 7.77459 9.73618L10.625 6.60041Z"
      fill="#23A0E1"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
