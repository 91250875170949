<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.1152 8.46205C12.7475 8.94639 14.5218 8.29398 15.3771 6.77295C16.391 4.97012 15.73 2.62968 13.9008 1.54544C12.0716 0.461198 9.76679 1.04373 8.75293 2.84657C7.89754 4.36762 8.23436 6.27136 9.45913 7.48047L7.10661 11.6637C6.44221 12.8452 5.08796 13.1875 3.88921 12.4769C3.34835 12.1563 2.98309 11.5834 2.8266 10.9111C2.66618 10.2219 2.76036 9.57626 2.96649 9.20972L3.74863 9.67332C4.07076 9.86425 4.4484 9.57471 4.34674 9.21473L3.42098 5.93679C3.31946 5.57732 2.84109 5.50631 2.66234 5.82417L1.31044 8.22812C0.828165 9.0857 0.740223 10.2363 0.983797 11.2827C1.23131 12.346 1.85615 13.4481 2.97134 14.1091C5.08469 15.3617 7.59133 14.7282 8.76266 12.6453L11.1152 8.46205ZM13.7211 5.79136C13.2142 6.69277 12.0618 6.98404 11.1472 6.44192C10.2325 5.8998 9.90205 4.72958 10.409 3.82817C10.9159 2.92675 12.0683 2.63548 12.9829 3.1776C13.8975 3.71972 14.228 4.88994 13.7211 5.79136Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
