<template>
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.4517 22.3926H33.9163"
      stroke="#a0a0a0"
      stroke-width="5.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0837 22.3926H6.54828"
      stroke="#a0a0a0"
      stroke-width="5.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.3929 6.54785V10.0833"
      stroke="#a0a0a0"
      stroke-width="5.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.3929 33.916V37.4514"
      stroke="#a0a0a0"
      stroke-width="5.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.3519 10.7959L13.8518 13.2958"
      stroke="#a0a0a0"
      stroke-width="5.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.7039 30.1475L33.2039 32.6474"
      stroke="#b0b0b0"
      stroke-width="5.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.7962 32.6474L13.2961 30.1475"
      stroke="#c0c0c0"
      stroke-width="5.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.1483 13.2958L32.6482 10.7959"
      stroke="#d0d0d0"
      stroke-width="5.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
