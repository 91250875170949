export enum UiDictionaryType {
  FishType = 'fish_type',
  //
  // local dictionaries
  FishFeedBuoyancy = 'fish_feed_buoyancy',
  FishFeedType = 'fish_feed_type',
  VideoHosting = 'video_hosting',
  FeedDocumentType = 'feed_document_type',
  DayOfWeek = 'day_of_week',
  PaymentType = 'payment_type',
}
