<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 3.75V5.75V8.75V10.75C2 11.7165 2.7835 12.5 3.75 12.5H7C7.82843 12.5 8.5 11.8284 8.5 11V9.5H9.75C10.1642 9.5 10.5 9.16421 10.5 8.75C10.5 8.33579 10.1642 8 9.75 8H8.5V6.5H12V7C12 7.41421 12.3358 7.75 12.75 7.75C13.1642 7.75 13.5 7.41421 13.5 7V5.75V3.75C13.5 2.7835 12.7165 2 11.75 2H7.75H3.75C2.7835 2 2 2.7835 2 3.75ZM7 8V6.5H3.5V8H7ZM3.5 9.5V10.75C3.5 10.8881 3.61193 11 3.75 11H7V9.5H3.5ZM3.75 3.5C3.61193 3.5 3.5 3.61193 3.5 3.75V5H7V3.5H3.75ZM12 5H8.5V3.5H11.75C11.8881 3.5 12 3.61193 12 3.75V5ZM10.2197 11.1161C9.92679 11.409 9.92679 11.8839 10.2197 12.1768C10.5126 12.4696 10.9875 12.4696 11.2803 12.1768L12 11.4571V14.6464C12 15.0606 12.3358 15.3964 12.75 15.3964C13.1642 15.3964 13.5 15.0606 13.5 14.6464V11.4571L14.2197 12.1768C14.5126 12.4696 14.9875 12.4696 15.2803 12.1768C15.5732 11.8839 15.5732 11.409 15.2803 11.1161L13.4571 9.29287C13.0666 8.90235 12.4334 8.90235 12.0429 9.29287L10.2197 11.1161Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
