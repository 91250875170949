import { singleton } from 'tsyringe';
import axios, { Axios, type AxiosRequestConfig } from 'axios';
import { ConfigProvider } from '~/app-modules/core/ioc/config.provider';

@singleton()
export class HttpConnection {
  constructor(configProvider: ConfigProvider) {
    this.http = axios.create({
      baseURL: configProvider.getPublicRuntimeConfig().app.apiUrl,
    });
  }

  private http: Axios;

  getUri() {
    return this.http.getUri();
  }

  async executeRequest(options: AxiosRequestConfig): Promise<{ [key: string]: any }> {
    const _result = await this.http.request(options);
    return _result.data;
  }
}

export class RequestRetryLimitExceededException extends Error {
  message = 'Request Retry limit was exceeded';
}
