<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5625 2.27363C3.5625 1.57022 4.11901 1 4.73077 1H10.2692C10.881 1 11.4375 1.57022 11.4375 2.27363V3.06965C11.4375 3.77306 10.881 4.34328 10.2692 4.34328H9.68846C9.55039 4.34328 9.43846 4.4712 9.43846 4.629V5.34542C9.43846 5.50321 9.55039 5.63113 9.68846 5.63113H10.3385C11.2561 5.63113 12 6.48647 12 7.54158V13.0896C12 14.1447 11.2561 15 10.3385 15H4.66154C3.7439 15 3 14.1447 3 13.0896V6.97015C3 5.91504 3.7439 5.63113 4.66154 5.63113H5.31154C5.44961 5.63113 5.56154 5.50321 5.56154 5.34542V4.629C5.56154 4.4712 5.44961 4.34328 5.31154 4.34328H4.73077C4.11901 4.34328 3.5625 3.77306 3.5625 3.06965V2.27363Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
