<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.25 1C6.69772 1 6.25 1.44772 6.25 2V2.67893C5.98222 2.76695 5.72335 2.87462 5.47511 3.00023L4.99479 2.51992C4.60427 2.1294 3.9711 2.1294 3.58058 2.51992L2.51992 3.58058C2.1294 3.9711 2.1294 4.60427 2.51992 4.99479L3.00023 5.47511C2.87462 5.72335 2.76695 5.98222 2.67893 6.25H2C1.44772 6.25 1 6.69771 1 7.25V8.75C1 9.30228 1.44772 9.75 2 9.75H2.6789C2.76692 10.0178 2.87459 10.2767 3.0002 10.5249L2.51992 11.0052C2.1294 11.3957 2.1294 12.0289 2.51992 12.4194L3.58058 13.4801C3.97111 13.8706 4.60427 13.8706 4.99479 13.4801L5.47504 12.9998C5.7233 13.1255 5.98219 13.2331 6.25 13.3212V14C6.25 14.5523 6.69772 15 7.25 15H8.75C9.30228 15 9.75 14.5523 9.75 14V13.3212C10.0178 13.2332 10.2767 13.1255 10.525 12.9999L11.0052 13.4801C11.3957 13.8706 12.0289 13.8706 12.4194 13.4801L13.4801 12.4194C13.8706 12.0289 13.8706 11.3957 13.4801 11.0052L12.9999 10.525C13.1255 10.2767 13.2332 10.0178 13.3212 9.75H14C14.5523 9.75 15 9.30229 15 8.75V7.25C15 6.69772 14.5523 6.25 14 6.25H13.3212C13.2331 5.98219 13.1255 5.7233 12.9998 5.47504L13.4801 4.9948C13.8706 4.60428 13.8706 3.97111 13.4801 3.58059L12.4194 2.51993C12.0289 2.1294 11.3957 2.1294 11.0052 2.51993L10.5249 3.0002C10.2767 2.87459 10.0178 2.76692 9.75 2.6789V2C9.75 1.44772 9.30229 1 8.75 1H7.25ZM7.9999 10.8C9.54629 10.8 10.7999 9.54642 10.7999 8.00003C10.7999 6.45363 9.54629 5.20003 7.9999 5.20003C6.4535 5.20003 5.19989 6.45363 5.19989 8.00003C5.19989 9.54642 6.4535 10.8 7.9999 10.8Z"
      fill-opacity="0.64"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
