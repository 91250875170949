import { z } from 'zod';
import { AuthorizedClient } from '~/app-modules/core/clients/authorized-client';
import { HttpConnection } from '~/app-modules/core/clients/http-connection';
import { AuthService } from '~/app-modules/auth/services/auth.service';
import { OrganizationStatuses } from '~/app-modules/organizations/enums/organization-statuses';
import { PaymentType } from '~/app-modules/organizations/enums/payment-type';
import {
  CurrentOrganizationDtoSchema,
  type CurrentOrganizationDto,
  OrganizationDtoSchema,
  OrganizationDto,
} from '~/app-modules/organizations/clients/dto/organization.dto';
import {
  OrganizationListItemDto,
  OrganizationListItemDtoSchema,
} from '~/app-modules/organizations/clients/dto/organization-list-item.dto';
import { NotAuthorizedClient } from '~/app-modules/core/clients/not-authorized-client';

@singleton()
export class OrganizationsClient extends NotAuthorizedClient {
  constructor(http: HttpConnection) {
    super(http);
  }

  async getAll(): Promise<OrganizationListItemDto[]> {
    return await this.executeRequest(
      {
        method: 'GET',
        url: '/v1/organizations/all',
      },
      z.array(OrganizationListItemDtoSchema)
    );
  }

  /**
   * Открытый запрос получения организации по id, dto отличается от getCurrent
   */
  async getById(id: string): Promise<OrganizationDto> {
    return await this.executeRequest(
      {
        method: 'GET',
        url: `/v1/organizations/${id}`,
      },
      OrganizationDtoSchema
    );
  }

  async getByAlias(alias: string): Promise<OrganizationDto> {
    return await this.executeRequest(
      {
        method: 'GET',
        url: `/v1/organizations/by-alias/${alias}`,
      },
      OrganizationDtoSchema
    );
  }

  getFileUrl(fileId: string, organizationId: string) {
    return new URL(
      `/v1/organizations/${organizationId}/file/${fileId}`,
      this.connection.getUri()
    ).toString();
  }
}

@singleton()
export class OrganizationsAuthorizedClient extends AuthorizedClient {
  constructor(http: HttpConnection, authService: AuthService) {
    super(http, authService);
  }

  async getCurrent(): Promise<CurrentOrganizationDto> {
    return await this.executeRequest(
      {
        method: 'GET',
        url: '/v1/current-organization',
      },
      CurrentOrganizationDtoSchema
    );
  }

  async update(
    status: OrganizationStatuses,
    customStatus: string | null,
    isVatPayer: boolean,
    phoneNumber: string,
    email: string,
    paymentTypes: PaymentType[],
    description: string,
    logoId: string | null,
    documentIds: string[]
  ) {
    await this.executeRequest({
      method: 'POST',
      data: {
        status,
        customStatus,
        isVatPayer,
        phoneNumber,
        email,
        paymentTypes,
        description,
        logoId,
        documentIds,
      },
      url: '/v1/current-organization',
    });
  }

  async uploadLogo(file: File): Promise<string> {
    const form = new FormData();
    form.append('file', file);
    return await this.executeRequest({
      method: 'POST',
      data: form,
      url: '/v1/current-organization/logo',
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  async uploadDocument(file: File): Promise<string> {
    const form = new FormData();
    form.append('file', file);
    return await this.executeRequest({
      method: 'POST',
      data: form,
      url: '/v1/current-organization/document',
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}
