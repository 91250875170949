import { singleton } from 'tsyringe';
import { NotAuthorizedClient } from '~/app-modules/core/clients/not-authorized-client';
import { HttpConnection } from '~/app-modules/core/clients/http-connection';
import { TokensPairDtoSchema } from '~/app-modules/auth/clients/dto/tokens-pair.dto';
import { SsoRedirectUrlDtoSchema } from '~/app-modules/auth/clients/dto/sso-redirect-url.dto';

@singleton()
export class AuthClient extends NotAuthorizedClient {
  constructor(connection: HttpConnection) {
    super(connection);
  }

  /**
   * Получение ссылки на авторизацию по SSO
   */
  async getSsoSignInUrl() {
    return await this.executeRequest(
      {
        method: 'get',
        url: '/v1/auth/sign-in/url',
      },
      SsoRedirectUrlDtoSchema
    );
  }

  /**
   * Авторизация юзера по sso коду
   * @param authorizationCode - код, передаваемый sso в redirect url
   */
  async authorizeBySsoCode(authorizationCode: string) {
    return await this.executeRequest(
      {
        method: 'post',
        url: '/v1/auth/authorize',
        data: {
          authorizationCode,
        },
      },
      TokensPairDtoSchema
    );
  }

  /**
   * Получение пары access и refresh токенов по refresh токену
   * @param refreshToken - refresh токен
   */
  async refresh(refreshToken: string) {
    return await this.executeRequest(
      {
        method: 'post',
        url: '/v1/auth/token',
        data: {
          refreshToken,
        },
      },
      TokensPairDtoSchema
    );
  }
}
