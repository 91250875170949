<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      d="M9.68921 1.07074C9.81458 1.12054 9.92969 1.19568 10.0269 1.29291L13.2072 4.47308C13.3947 4.66064 13.5 4.91498 13.5 5.18018V12.75C13.5 13.7165 12.7166 14.5 11.75 14.5H7.75C7.33579 14.5 7 14.1642 7 13.75C7 13.3358 7.33579 13 7.75 13H11.75C11.7932 13 11.8339 12.989 11.8693 12.9697C11.9471 12.9274 12 12.8448 12 12.75V6.25C12 5.97388 11.7761 5.75 11.5 5.75H9.75C9.19775 5.75 8.75 5.30231 8.75 4.75V3C8.75 2.72388 8.52612 2.5 8.25 2.5H5.75C5.61194 2.5 5.5 2.61194 5.5 2.75V4.25V5V6.25C5.5 6.66421 5.16421 7 4.75 7C4.33579 7 4 6.66421 4 6.25V5V4.25V2.75C4 1.78351 4.78345 1 5.75 1H9.31982C9.44751 1 9.57275 1.02441 9.68921 1.07074ZM4.21967 9.28033C3.92678 8.98744 3.92678 8.51256 4.21967 8.21967C4.51256 7.92678 4.98744 7.92678 5.28033 8.21967L7.10355 10.0429C7.49408 10.4334 7.49408 11.0666 7.10355 11.4571L5.28033 13.2803C4.98744 13.5732 4.51256 13.5732 4.21967 13.2803C3.92678 12.9874 3.92678 12.5126 4.21967 12.2197L4.93934 11.5H1.75C1.33579 11.5 1 11.1642 1 10.75C1 10.3358 1.33579 10 1.75 10H4.93934L4.21967 9.28033Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
