// Strict map NOT compatible for SSR and needs to re-init from client
export class StrictMap<K, T> extends Map<K, T> {
  strictGet(key: K): T {
    if (!this.has(key)) throw new Error(`Missing value for ${String(key)}`);
    return this.get(key)!;
  }
}

// Просто так выполнить override метода ломает реактивность vue,
// т.к. значения пытают проксироваться, и строгий get начинает валить приложение
