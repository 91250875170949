<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.8291 1.77928C3.15366 1.29243 3.70007 1 4.28518 1H10.2148C10.7999 1 11.3463 1.29243 11.6709 1.77928L13.2061 4.08205C13.3977 4.36952 13.5 4.70728 13.5 5.05278V8C13.5 8.41422 13.1642 8.75 12.75 8.75C12.3358 8.75 12 8.41422 12 8V5.5H2.5V11.75C2.5 11.8881 2.61193 12 2.75 12H8C8.41422 12 8.75 12.3358 8.75 12.75C8.75 13.1642 8.41422 13.5 8 13.5H2.75C1.7835 13.5 1 12.7165 1 11.75V5.05278C1 4.70728 1.10227 4.36952 1.29391 4.08205L2.8291 1.77928ZM4.28518 2.5C4.2016 2.5 4.12354 2.54178 4.07717 2.61133L3.15139 4H11.3486L10.4228 2.61133C10.3765 2.54178 10.2984 2.5 10.2148 2.5H4.28518ZM5 7.75C5 7.33579 5.33579 7 5.75 7H8.75C9.16422 7 9.5 7.33579 9.5 7.75C9.5 8.16422 9.16422 8.5 8.75 8.5H5.75C5.33579 8.5 5 8.16422 5 7.75ZM14.5761 10.9802C14.8413 10.662 14.7983 10.189 14.4801 9.92386C14.1619 9.65868 13.689 9.70168 13.4238 10.0199L11.5668 12.2482L11.124 11.584C10.8942 11.2394 10.4286 11.1462 10.0839 11.376C9.73929 11.6058 9.64616 12.0714 9.87592 12.4161L10.8759 13.9161C11.0072 14.1129 11.2237 14.2363 11.46 14.249C11.6962 14.2616 11.9246 14.1619 12.0761 13.9802L14.5761 10.9802Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
