<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 3.75V5.75V8.75V10.75C1 11.7165 1.7835 12.5 2.75 12.5H6C6.82843 12.5 7.5 11.8284 7.5 11V9.5H8.75C9.16421 9.5 9.5 9.16421 9.5 8.75C9.5 8.33579 9.16421 8 8.75 8H7.5V6.5H11V7C11 7.41421 11.3358 7.75 11.75 7.75C12.1642 7.75 12.5 7.41421 12.5 7V5.75V3.75C12.5 2.7835 11.7165 2 10.75 2H6.75H2.75C1.7835 2 1 2.7835 1 3.75ZM6 8V6.5H2.5V8H6ZM2.5 9.5V10.75C2.5 10.8881 2.61193 11 2.75 11H6V9.5H2.5ZM2.75 3.5C2.61193 3.5 2.5 3.61193 2.5 3.75V5H6V3.5H2.75ZM11 5H7.5V3.5H10.75C10.8881 3.5 11 3.61193 11 3.75V5ZM12.5 14.5C13.6046 14.5 14.5 13.6046 14.5 12.5C14.5 12.2229 14.4436 11.9589 14.3417 11.7189L11.7189 14.3417C11.9589 14.4436 12.2229 14.5 12.5 14.5ZM13.2811 10.6583L10.6583 13.2811C10.5564 13.0411 10.5 12.7771 10.5 12.5C10.5 11.3954 11.3954 10.5 12.5 10.5C12.7771 10.5 13.0411 10.5564 13.2811 10.6583ZM12.5 16C14.433 16 16 14.433 16 12.5C16 10.567 14.433 9 12.5 9C10.567 9 9 10.567 9 12.5C9 14.433 10.567 16 12.5 16Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
