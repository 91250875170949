import Color from 'color';
import { toRaw, isRef, isReactive, isProxy } from 'vue';
import { ensureInIntegerRange } from '~/app-modules/core/utils/validation.helpers';

/**
 * nameof
 * Returns the passed property name but will generate a compile
 * time error when the property name does not exist on type T
 *   interface Person { firstName: string; lastName: string; }
 *   const personName1 = nameof<Person>("firstName"); // => "firstName"
 * @param name - string | number | symbol
 */
export const nameof = <T>(name: Extract<keyof T, string>): string => name;

/**
 * formatToMMSS
 * Returns the passed seconds to formatted to time string value
 * Hides zero hours and minutes over 9 ('00:09:59'=>'9:59', '00:00:01'=>'0:01')
 * @param seconds - number
 */
export function formatToMMSS(seconds: number) {
  ensureInIntegerRange({ seconds }, { min: 0, max: 60 * 60 * 24 });
  return new Date(1000 * seconds)
    .toISOString()
    .substring(11, 19)
    .replace(/^00:0|^00:|^0/, '');
}

export function RgbaToHex6(color: Color, background: Color = Color.rgb(255, 255, 255)) {
  const red = (1 - color.alpha()) * background.red() + color.alpha() * color.red();
  const green = (1 - color.alpha()) * background.green() + color.alpha() * color.green();
  const blue = (1 - color.alpha()) * background.blue() + color.alpha() * color.blue();

  return Color.rgb(red, green, blue).hex();
}

export function generateThemeColorList(name: string, baseColor: Color) {
  const template = {
    [name]: 1,
    [name + '4']: 0.04,
    [name + '8']: 0.08,
    [name + '12']: 0.12,
    [name + '16']: 0.16,
    [name + '24']: 0.24,
    [name + '32']: 0.32,
    [name + '48']: 0.48,
    [name + '64']: 0.64,
    [name + '80']: 0.8,
  };

  const result: Record<string, string> = {};

  for (const [name, opacity] of Object.entries(template)) {
    result[name] = RgbaToHex6(
      Color.rgb(baseColor.red(), baseColor.green(), baseColor.blue()).alpha(opacity)
    );
  }

  return result;
}

export function colorBrighter(baseColor: Color, alpha: number) {
  return RgbaToHex6(baseColor.alpha(alpha));
}

export function capitalizeFirstLetter(string?: string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

export function capitalizeFirstWordLetters(string?: string) {
  return string
    ? string
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : '';
}

/**
 * enumToUIItems
 * возвращает массив { title, value } для использования в списках
 * @param enm - enum
 * @param titlesMap - Map<ключ enum, ui текст>
 */
export function enumToUIItems<TEnum, T extends { [p: string]: TEnum } | TEnum[]>(
  enm: T,
  titlesMap: Map<TEnum, string>
) {
  return Object.values(enm).map((value) => ({
    title: titlesMap.get(value) || '',
    value,
  }));
}

/**
 * mapToUIItems
 * возвращает массив { title, value }
 * для использования в списках
 * @param map - Map<ключ enum, ui текст>
 */
export function mapToUIItems(map: Map<string, string>) {
  return [...map].map(([value, title]) => ({ value, title }));
}

/**
 * mapToPlainUIItems
 * возвращает массив value
 * для использования в списках
 * @param map - Map<ключ enum, ui текст>
 */
export function mapToPlainUIItems(map: Map<string, string>) {
  return [...map].map(([_value, title]) => title);
}

/**
 * LogRunningFrom
 * Для отладки
 * @param from
 */
export function LogRunningFrom(from: string, data?: any) {
  console.log(
    `${process.server ? 'Server side::' : 'Client side::'}        ${from}       data:`,
    data
  );
}

/**
 * toRawDeep
 * Returns the raw, original object of a Vue-created proxy with deep
 * @param sourceObj
 */
export function toRawDeep<T>(sourceObj: T): T {
  const objectIterator = (input: any): any => {
    if (Array.isArray(input)) {
      return input.map((item) => objectIterator(item));
    }
    if (isRef(input) || isReactive(input) || isProxy(input)) {
      return objectIterator(toRaw(input));
    }
    if (input && typeof input === 'object') {
      return Object.keys(input).reduce((acc, key) => {
        acc[key as keyof typeof acc] = objectIterator(input[key]);
        return acc;
      }, {} as T);
    }
    return input;
  };

  return objectIterator(sourceObj);
}
