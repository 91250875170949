<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 2.25C4.82436 2.25 2.25 4.82436 2.25 8C2.25 9.63077 2.9279 11.1019 4.0193 12.1493C4.31816 12.4361 4.32794 12.9109 4.04114 13.2097C3.75433 13.5086 3.27956 13.5184 2.9807 13.2316C1.60676 11.9131 0.75 10.056 0.75 8C0.75 3.99594 3.99594 0.75 8 0.75C11.3701 0.75 14.2031 3.04941 15.0157 6.16492L16.5 6L14.75 9L12 6.5L13.5046 6.33282C12.79 3.97018 10.5959 2.25 8 2.25ZM14.8355 10.3292C15.206 10.5144 15.3561 10.9649 15.1709 11.3354C15.0983 11.4806 15.0158 11.6277 14.9243 11.7752C14.706 12.1272 14.2437 12.2356 13.8917 12.0173C13.5397 11.799 13.4313 11.3367 13.6496 10.9846C13.7187 10.8732 13.7785 10.766 13.8292 10.6646C14.0145 10.2941 14.465 10.1439 14.8355 10.3292ZM13.1101 12.829C13.3571 13.1615 13.2877 13.6313 12.9552 13.8783C12.7043 14.0646 12.4365 14.2415 12.1527 14.4045C11.7935 14.6107 11.3351 14.4867 11.1288 14.1275C10.9226 13.7683 11.0466 13.3099 11.4058 13.1037C11.6365 12.9712 11.8552 12.8268 12.0608 12.6741C12.3933 12.4271 12.8631 12.4964 13.1101 12.829ZM4.8102 13.6956C4.97829 13.3171 5.42145 13.1464 5.80003 13.3145C5.9237 13.3694 6.04618 13.4201 6.16744 13.4666C6.55415 13.615 6.74732 14.0488 6.5989 14.4355C6.45048 14.8222 6.01667 15.0154 5.62996 14.867C5.4844 14.8111 5.33817 14.7507 5.19132 14.6854C4.81274 14.5174 4.64211 14.0742 4.8102 13.6956ZM10.0651 14.5005C10.1207 14.911 9.833 15.2888 9.42253 15.3444C9.10821 15.387 8.78474 15.4096 8.453 15.4095C8.03878 15.4094 7.70308 15.0736 7.70317 14.6594C7.70327 14.2452 8.03914 13.9094 8.45335 13.9095C8.71644 13.9096 8.97253 13.8917 9.22122 13.858C9.63168 13.8024 10.0095 14.0901 10.0651 14.5005Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
