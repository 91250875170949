import type { VNode, Directive } from 'vue';
import Vue from 'vue';
import Inputmask from 'inputmask';

function bindMask(
  el: HTMLElement,
  options: Inputmask.Options,
  vnode: VNode | any,
  customId?: string
) {
  const input = el.querySelector(customId ? `input#${customId}` : 'input');

  if (!input) return;

  const onKeyValidation = async (_key: number, result: boolean) => {
    if (result) {
      return;
    }

    if (vnode?.ctx?.ctx?.validate) {
      await vnode.ctx.ctx.validate();
    }
  };

  // автозаполнение браузера можно опознать по не официальному code === undefined, что позволяет
  // исправить проблему автозаполнения поля с маской inputmask+vuetify3
  el.addEventListener(
    'keydown',
    function (event) {
      // code === undefined значит автозаполнение
      if (event.code === undefined) {
        // удаляем маску, в поле появляется текст со значением автозаполнения
        // это значение попадает в модель полей vue
        event.target?.inputmask && event.target?.inputmask.remove();
      }
    },
    true
  );

  el.addEventListener(
    'keyup',
    function (event) {
      // code === undefined значит автозаполнение
      if (!event.target?.inputmask && event.code === undefined) {
        // добавляем маску
        Inputmask({
          onKeyValidation,
          autoUnmask: true,
          ...options,
        }).mask(input as HTMLElement);

        if (event.target?.inputmask && vnode?.ctx) {
          // заносим корректное значение поля в модель поля
          vnode.ctx.emit('update:modelValue', event.target.inputmask.unmaskedvalue());
        }
      }
    },
    true
  );

  Inputmask({
    onKeyValidation,
    autoUnmask: true,
    ...options,
  }).mask(input as HTMLElement);
}

export const inputmaskDirective = {
  mounted: function (el: HTMLElement, binding: Vue.DirectiveBinding, vnode: VNode) {
    bindMask(el, binding.value, vnode, binding.arg);
  },

  unmounted: function (el: HTMLElement, binding: Vue.DirectiveBinding) {
    const customId = binding.arg;
    const input = el.querySelector(customId ? `input#${customId}` : 'input');

    if (!input) return;

    input.inputmask && input.inputmask.remove();
  },

  updated: function (el: HTMLElement, binding: Vue.DirectiveBinding) {
    if (binding.value === binding.oldValue) return;

    const customId = binding.arg;
    const input = el.querySelector(customId ? `input#${customId}` : 'input');

    if (!input) return;

    input.inputmask && input.inputmask.remove();

    Inputmask(binding.value).mask(input as HTMLElement);
  },
};
export default defineNuxtPlugin((nuxt) => {
  nuxt.vueApp.directive('mask', inputmaskDirective);
});

declare module 'vue' {
  interface ComponentCustomProperties {
    vMask: Directive<HTMLElement, Inputmask.Options>;
  }
}

declare global {
  interface EventTarget {
    inputmask: InstanceType<typeof Inputmask>;
  }
}
