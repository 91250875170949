<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.75 1C4.75 0.585786 4.41421 0.25 4 0.25C3.58579 0.25 3.25 0.585786 3.25 1V2H3C1.89543 2 1 2.89543 1 4V11C1 12.1046 1.89543 13 3 13H5.96967C6.16858 13 6.35935 12.921 6.5 12.7803C6.97247 12.3079 6.63785 11.5 5.96967 11.5H3C2.72386 11.5 2.5 11.2761 2.5 11V6C2.5 5.72386 2.72386 5.5 3 5.5H12C12.2761 5.5 12.5 5.72386 12.5 6V6.18934C12.5 6.85752 13.3079 7.19214 13.7803 6.71967C13.921 6.57902 14 6.38825 14 6.18934V4C14 2.89543 13.1046 2 12 2H11.75V1C11.75 0.585786 11.4142 0.25 11 0.25C10.5858 0.25 10.25 0.585786 10.25 1V2H4.75V1Z"
    />
    <path
      d="M14.2058 10.5854L12.4146 8.79428L12.9466 8.2623C13.2963 7.91257 13.8633 7.91257 14.2131 8.2623L14.7377 8.78691C15.0874 9.13665 15.0874 9.70369 14.7377 10.0534L14.2058 10.5854Z"
    />
    <path d="M8 15V13.2089L11.8016 9.40736L13.5927 11.1985L9.79113 15H8Z" />
  </svg>
</template>

<style scoped>
svg {
  fill: currentColor;
}
</style>
