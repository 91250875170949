<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.70437 0.290168C10.0964 0.679182 10.0988 1.31234 9.70983 1.70437L5.7406 5.70437C5.55379 5.89263 5.29984 5.99897 5.03462 5.99999C4.7694 6.00101 4.51463 5.89663 4.32638 5.70981L0.295608 1.70981C-0.09641 1.32079 -0.0988359 0.687625 0.290189 0.295608C0.679214 -0.0964099 1.31237 -0.0988359 1.70439 0.290189L5.02533 3.58578L8.29017 0.295629C8.67918 -0.0964005 9.31234 -0.0988452 9.70437 0.290168Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
