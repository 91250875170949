<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 8C14.25 11.4518 11.4518 14.25 8 14.25C4.54822 14.25 1.75 11.4518 1.75 8C1.75 4.54822 4.54822 1.75 8 1.75C11.4518 1.75 14.25 4.54822 14.25 8Z"
      stroke-width="1.5"
      fill="none"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.00005 4.99999C5.39057 4.60946 6.02374 4.60946 6.41426 4.99999L8.00008 6.58584L9.58605 5C9.97658 4.60948 10.6097 4.60948 11.0003 5C11.3908 5.39052 11.3908 6.02369 11.0003 6.41421L9.4143 8.00005L11.0003 9.586C11.3908 9.97653 11.3908 10.6097 11.0003 11.0002C10.6097 11.3907 9.97658 11.3907 9.58606 11.0002L8.00008 9.41426L6.41417 11.0001C6.02364 11.3906 5.39048 11.3906 4.99995 11.0001C4.60943 10.6095 4.60943 9.97638 4.99995 9.58585L6.58587 8.00005L5.00005 6.4142C4.60952 6.02367 4.60952 5.39051 5.00005 4.99999Z"
      stroke="none"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
  stroke: currentColor;
}
</style>
