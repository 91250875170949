<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 4C16 6.20914 14.2091 8 12 8C9.79086 8 8 6.20914 8 4C8 1.79086 9.79086 0 12 0C14.2091 0 16 1.79086 16 4ZM12.75 2C12.75 1.58579 12.4142 1.25 12 1.25C11.5858 1.25 11.25 1.58579 11.25 2V3.25L10 3.25C9.58579 3.25 9.25 3.58579 9.25 4C9.25 4.41421 9.58579 4.75 10 4.75H11.25V6C11.25 6.41421 11.5858 6.75 12 6.75C12.4142 6.75 12.75 6.41421 12.75 6V4.75H14C14.4142 4.75 14.75 4.41421 14.75 4C14.75 3.58579 14.4142 3.25 14 3.25H12.75V2ZM12.8167 9C12.9696 9 13.0864 9.13657 13.0627 9.28768C12.9304 10.1315 12.2432 10.75 11.4381 10.75H4.59977L4.6949 11.532C4.71006 11.6567 4.80997 11.75 4.92826 11.75H11.6303C12.0202 11.75 12.3362 12.0858 12.3362 12.5C12.3362 12.9142 12.0202 13.25 11.6303 13.25H4.92826C4.10023 13.25 3.40092 12.5968 3.29478 11.7243L2.22958 2.96795C2.21442 2.84331 2.11452 2.75 1.99623 2.75H0.941234C0.551386 2.75 0.235352 2.41421 0.235352 2C0.235352 1.58579 0.551386 1.25 0.941234 1.25H1.99623C2.82426 1.25 3.52356 1.90316 3.6297 2.77568L3.6874 3.25H6.8C6.91046 3.25 7 3.33954 7 3.45V4.55C7 4.66046 6.91046 4.75 6.8 4.75H3.86987L4.41729 9.25H11.4381C11.5531 9.25 11.6513 9.16164 11.6702 9.0411C11.6739 9.01744 11.6943 9 11.7182 9H12.8167ZM5.64712 16C6.16691 16 6.58829 15.5523 6.58829 15C6.58829 14.4477 6.16691 14 5.64712 14C5.12732 14 4.70594 14.4477 4.70594 15C4.70594 15.5523 5.12732 16 5.64712 16ZM10.353 16C10.8728 16 11.2942 15.5523 11.2942 15C11.2942 14.4477 10.8728 14 10.353 14C9.8332 14 9.41182 14.4477 9.41182 15C9.41182 15.5523 9.8332 16 10.353 16Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
