import { type TokensPairDto } from '~/app-modules/auth/clients/dto/tokens-pair.dto';

export interface ITokensPair {
  accessToken: string;
  refreshToken: string;
  updated: Date;
}

const useTokensStore = defineStore('TokensStore', () => {
  const tokens = useCookie<ITokensPair | null>('fishplan-market-auth', { default: () => null });

  const accessToken = computed(() => {
    return tokens.value?.accessToken ? `Bearer ${tokens.value?.accessToken}` : '';
  });

  const refreshToken = computed(() => {
    return `${tokens.value?.refreshToken || ''}`;
  });

  function setTokens(tokensPair: TokensPairDto) {
    dropTokens();
    tokens.value = {
      accessToken: tokensPair.accessToken,
      refreshToken: tokensPair.refreshToken,
      updated: new Date(),
    };
  }

  function dropTokens() {
    tokens.value = null;
  }

  return {
    tokens,
    accessToken,
    refreshToken,
    setTokens,
    dropTokens,
  };
});

@injectable()
export class TokensStoreProvider {
  getStore = () => useTokensStore();
}
