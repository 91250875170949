import { skipHydrate } from 'pinia';
import { useLocalStorage } from '@vueuse/core';
import { CartFeedItemDto } from '~/app-modules/cart/clients/dto/cart.dto';

const useCartStore = defineStore('CartStore', () => {
  const cartItemsCount = useCookie<number>('fishplan-market-cart-items-count', {
    default: () => 0,
  });

  const cartLastUpdated = useCookie<Date>('fishplan-market-cart-last-updated', {
    default: () => new Date(),
  });

  const cartItems = useLocalStorage<CartFeedItemDto[]>('fishplan-market-cart', () => [], {
    deep: true,
  });

  watch(
    () => cartItems.value.length,
    (value) => {
      cartItemsCount.value = value;
    }
  );

  watch(
    () => cartItems.value,
    () => {
      cartLastUpdated.value = new Date();
    }
  );

  // Обновление размера корзины на клиенте (для устранения рассинхрона вкладок)
  if (process.client) {
    cartItemsCount.value = cartItems.value.length;
  }

  // Проверка срока хранения корзины - не более 7 дней
  const dayjs = useDayjs();
  const cartExpired = dayjs().subtract(7, 'days').isAfter(cartLastUpdated.value);
  if (cartExpired) {
    LogRunningFrom('cartExpired ! !');
    // cartItems.value = [];
  }

  function addOrUpdateCartFeedItem(
    feedId: string,
    pelletSize: number,
    packageSize: number,
    packageCount: number
  ) {
    const existingItem = cartItems.value.find(
      (el) => el.feedId === feedId && el.pelletSize === pelletSize
    );

    // добавление больше 500 элементов заблокировано
    if (!existingItem && cartItems.value.length === 500) {
      // добавление свыше 500 позиций в корзину выглядит недостижимым, API не допускает этого
      // TODO: возможно необходимо уведомление юзеру
      return;
    }

    const existingItemPackage =
      existingItem && existingItem.packages.find((p) => p.size === packageSize);

    // Обновить только количество корма для заданной упаковки
    if (existingItem && existingItemPackage) {
      existingItemPackage.count = packageCount;
      //
      // Добавить упаковку с указанным объемом корма
    } else if (existingItem && !existingItemPackage) {
      existingItem.packages.push({
        size: packageSize,
        count: packageCount,
      });
      //
      // Добавить полностью новый корм
    } else {
      cartItems.value.push({
        feedId,
        pelletSize,
        packages: [{ size: packageSize, count: packageCount }],
      });
    }
  }

  function removeCartFeeds() {
    cartItems.value = [];
  }

  function removeCartFeedItem(feedId: string, pelletSize?: number) {
    cartItems.value = cartItems.value.filter(
      (el) => !(el.feedId === feedId && (pelletSize ? el.pelletSize === pelletSize : true))
    );
  }

  function removeCartFeedItemPackage(feedId: string, pelletSize: number, packageSize: number) {
    const item = cartItems.value.find((el) => el.feedId === feedId && el.pelletSize === pelletSize);
    if (!item) return;

    item.packages = item.packages.filter((p) => p.size !== packageSize);

    if (item.packages.length === 0) removeCartFeedItem(feedId, pelletSize);
  }

  function getCartFeedIds() {
    return cartItems.value.map((el) => el.feedId);
  }

  function getCartFeedItem(feedId: string | undefined, pelletSize: number | undefined) {
    return cartItems.value.find((el) => el.feedId === feedId && el.pelletSize === pelletSize);
  }

  function getCartFeedItemPackages(feedId: string, pelletSize: number) {
    const item = cartItems.value.find((el) => el.feedId === feedId && el.pelletSize === pelletSize);
    return item?.packages;
  }

  function getCartFeedItemPackage(feedId: string, pelletSize: number, packageSize: number) {
    const item = cartItems.value.find((el) => el.feedId === feedId && el.pelletSize === pelletSize);

    const pack = item && item.packages.find((p) => p.size === packageSize);

    return pack;
  }

  return {
    cartItems: skipHydrate(cartItems),
    cartItemsCount,
    cartLastUpdated,
    addOrUpdateCartFeedItem,
    removeCartFeeds,
    removeCartFeedItem,
    removeCartFeedItemPackage,
    getCartFeedIds,
    getCartFeedItem,
    getCartFeedItemPackages,
    getCartFeedItemPackage,
  };
});

@injectable()
export class CartStoreProvider {
  getStore = () => useCartStore();
}
