<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0755 0.62251L12.2511 1.06547C13.3817 1.49142 13.9529 2.75321 13.5269 3.88375L12.914 5.51039C13.953 5.92369 14.6875 6.93844 14.6875 8.12473C14.6875 8.37331 14.6553 8.61436 14.5947 8.84392C14.8986 9.05111 15.1847 9.28751 15.4484 9.5513C16.562 10.6648 17.1875 12.175 17.1875 13.7497C17.1875 15.3245 16.5619 16.8347 15.4484 17.9482C14.3349 19.0617 12.8247 19.6872 11.25 19.6872L5 19.6872C4.48223 19.6872 4.0625 19.2675 4.0625 18.7497C4.0625 18.232 4.48223 17.8122 5 17.8122H7.8125V15.9372H6.25C5.73223 15.9372 5.3125 15.5175 5.3125 14.9997C5.3125 14.482 5.73223 14.0622 6.25 14.0622H11.25C11.7678 14.0622 12.1875 14.482 12.1875 14.9997C12.1875 15.5175 11.7678 15.9372 11.25 15.9372H9.6875V17.8122H11.25C12.3274 17.8122 13.3607 17.3842 14.1226 16.6224C14.8845 15.8605 15.3125 14.8272 15.3125 13.7497C15.3125 12.6723 14.8845 11.639 14.1226 10.8771C13.9421 10.6966 13.7463 10.5348 13.5382 10.393C13.0724 10.7351 12.4973 10.9372 11.875 10.9372C11.5164 10.9372 11.1736 10.8701 10.8582 10.7478C10.2939 11.5264 9.25932 11.8651 8.31609 11.5097L7.14041 11.0667C6.00987 10.6408 5.43868 9.379 5.86464 8.24845L8.25718 1.89828C8.68313 0.767744 9.94491 0.196557 11.0755 0.62251ZM9.41282 9.48514C9.18959 9.08198 9.0625 8.6182 9.0625 8.12473C9.0625 6.90566 9.8381 5.86774 10.9228 5.47752L11.7723 3.22268C11.8332 3.06117 11.7516 2.88091 11.5901 2.82006L10.4144 2.37711C10.2529 2.31625 10.0726 2.39785 10.0118 2.55936L7.61923 8.90953C7.55838 9.07103 7.63998 9.25129 7.80149 9.31214L8.97716 9.7551C9.13867 9.81595 9.31893 9.73435 9.37978 9.57284L9.41282 9.48514ZM11.875 7.18723C11.3572 7.18723 10.9375 7.60696 10.9375 8.12473C10.9375 8.64249 11.3572 9.06223 11.875 9.06223C12.3928 9.06223 12.8125 8.64249 12.8125 8.12473C12.8125 7.60696 12.3928 7.18723 11.875 7.18723Z"
      fill="#23A0E1"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
