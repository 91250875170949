<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 8C14.2091 8 16 6.20914 16 4C16 1.79086 14.2091 0 12 0C9.79086 0 8 1.79086 8 4C8 6.20914 9.79086 8 12 8ZM13.0627 9.28768C13.0864 9.13657 12.9696 9 12.8167 9H11.7182C11.6943 9 11.6739 9.01744 11.6702 9.0411C11.6513 9.16164 11.5531 9.25 11.4381 9.25H4.41729L3.86987 4.75H6.8C6.91046 4.75 7 4.66046 7 4.55V3.45C7 3.33954 6.91046 3.25 6.8 3.25H3.6874L3.6297 2.77568C3.52356 1.90316 2.82426 1.25 1.99623 1.25H0.941234C0.551386 1.25 0.235352 1.58579 0.235352 2C0.235352 2.41421 0.551386 2.75 0.941234 2.75H1.99623C2.11452 2.75 2.21442 2.84331 2.22958 2.96795L3.29478 11.7243C3.40092 12.5968 4.10023 13.25 4.92826 13.25H11.6303C12.0202 13.25 12.3362 12.9142 12.3362 12.5C12.3362 12.0858 12.0202 11.75 11.6303 11.75H4.92826C4.80997 11.75 4.71006 11.6567 4.6949 11.532L4.59977 10.75H11.4381C12.2432 10.75 12.9304 10.1315 13.0627 9.28768ZM6.58829 15C6.58829 15.5523 6.16691 16 5.64712 16C5.12732 16 4.70594 15.5523 4.70594 15C4.70594 14.4477 5.12732 14 5.64712 14C6.16691 14 6.58829 14.4477 6.58829 15ZM11.2942 15C11.2942 15.5523 10.8728 16 10.353 16C9.8332 16 9.41182 15.5523 9.41182 15C9.41182 14.4477 9.8332 14 10.353 14C10.8728 14 11.2942 14.4477 11.2942 15ZM14.5607 3.56065C14.8536 3.26776 14.8536 2.79289 14.5607 2.49999C14.2678 2.2071 13.7929 2.2071 13.5 2.49999L11.75 4.47828L10.7803 3.50861C10.4874 3.21571 10.0126 3.21571 9.71967 3.50861C9.42678 3.8015 9.42678 4.27637 9.71967 4.56927L11.2197 6.06927C11.5126 6.36216 11.9874 6.36216 12.2803 6.06927L14.5607 3.56065Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
