import { StrictMap } from '~/app-modules/core/utils/strict-map';
import { FishFeedBuoyancy } from '~/app-modules/fish-feed/enums/FishFeedBuoyancy';
import { FishFeedType } from '~/app-modules/fish-feed/enums/FishFeedType';
import { UiDictionaryType } from '~/app-modules/core/enums/UiDictionaryType';
import { VideoHosting } from '~/app-modules/fish-feed/enums/VideoHosting';
import { DayOfWeek } from '~/app-modules/organizations/enums/day-of-week';
import { PaymentType } from '~/app-modules/organizations/enums/payment-type';
import { FeedDocumentType } from '~/app-modules/fish-feed/enums/FeedDocumentType';

const fishFeedBuoyancyMap = new StrictMap([
  [FishFeedBuoyancy.SINKING, 'Тонущий'],
  [FishFeedBuoyancy.SLOW_SINKING, 'Медленнотонущий'],
  [FishFeedBuoyancy.FAST_SINKING, 'Быстротонущий'],
  [FishFeedBuoyancy.FLOATING, 'Плавающий'],
  [FishFeedBuoyancy.OTHER, 'Другой'],
]);

const fishFeedTypeMap = new StrictMap([
  [FishFeedType.STARTER, 'Стартовый'],
  [FishFeedType.PRODUCTION, 'Продукционный'],
  [FishFeedType.REPRODUCTION, 'Репродукционный'],
  [FishFeedType.OTHER, 'Прочие'],
]);

const videoHostingMap = new StrictMap([
  [VideoHosting.DZEN, 'Дзен'],
  [VideoHosting.VK, 'ВКонтакте'],
  [VideoHosting.RUTUBE, 'RUTUBE'],
  [VideoHosting.YOUTUBE, 'YouTube'],
]);

const feedDocumentTypeMap = new StrictMap([
  [FeedDocumentType.EXPERTISE, 'Лабораторное исследование'],
  [FeedDocumentType.FEEDBACK, 'Отзыв'],
  [FeedDocumentType.OTHER, 'Другое'],
]);

const dayOfWeekMap = new StrictMap([
  [DayOfWeek.MONDAY, 'Понедельник'],
  [DayOfWeek.TUESDAY, 'Вторник'],
  [DayOfWeek.WEDNESDAY, 'Среда'],
  [DayOfWeek.THURSDAY, 'Четверг'],
  [DayOfWeek.FRIDAY, 'Пятница'],
  [DayOfWeek.SATURDAY, 'Суббота'],
  [DayOfWeek.SUNDAY, 'Воскресенье'],
]);

const paymentTypesMap = new StrictMap([
  [PaymentType.CARD, 'Картой'],
  [PaymentType.CASH, 'Наличными'],
  [PaymentType.BILL, 'По выставленному счету'],
  [PaymentType.ROBOKASSA, 'Робокасса'],
  [PaymentType.QR_CODE, 'По QR-коду'],
]);

export const localUIDictionaries = new StrictMap<UiDictionaryType, StrictMap<string, string>>([
  [UiDictionaryType.FishFeedBuoyancy, fishFeedBuoyancyMap],
  [UiDictionaryType.FishFeedType, fishFeedTypeMap],
  [UiDictionaryType.VideoHosting, videoHostingMap],
  [UiDictionaryType.FeedDocumentType, feedDocumentTypeMap],
  [UiDictionaryType.DayOfWeek, dayOfWeekMap],
  [UiDictionaryType.PaymentType, paymentTypesMap],
]);
