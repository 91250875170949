<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 398 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M398 2.27155V49.3323H387.647L361.494 15.2469V49.3323H351.074V2.27155H361.494L387.58 36.357V2.27155H398Z"
      fill="#0889CC"
    />
    <path
      d="M307.56 31.5837H323.964L315.695 12.0199L307.56 31.5837ZM304.871 38.5084L300.164 49.3323H289.206L310.451 2.27155H321.006L342.25 49.3323H331.292L326.518 38.5084H304.871Z"
      fill="#0889CC"
    />
    <path
      d="M283.44 41.2648V49.3323H246.329V2.27155H256.75V41.2648H283.44Z"
      fill="#0889CC"
    />
    <path
      d="M201.653 10.3391V24.9952H213.351C217.183 24.9952 219.469 24.5918 221.082 23.6506C222.427 22.8438 224.377 21.0959 224.377 17.3982C224.377 14.7763 223.234 13.0283 221.553 11.8854C220.074 10.9442 217.788 10.3391 213.485 10.3391H201.653ZM191.232 2.27155H215.435C219.603 2.27155 225.923 2.67493 230.226 6.10364C232.646 8.0533 235.133 11.4148 235.133 17.1965C235.133 22.1043 233.453 25.7347 231.301 27.9533C227.2 32.256 221.015 32.6594 214.561 32.6594H201.653V49.3323H191.232V2.27155Z"
      fill="#0889CC"
    />
    <path
      d="M141.238 28.4911V49.3323H130.817V2.27155H141.238V20.558H166.046V2.27155H176.466V49.3323H166.046V28.4911H141.238Z"
      fill="#0889CC"
    />
    <path
      d="M72.8341 34.2059H83.6581V34.4748C83.6581 36.4917 84.0615 39.2481 87.2213 41.1305C89.5071 42.4751 93.3392 42.9457 96.1628 42.9457C98.7175 42.9457 102.281 42.6768 104.499 41.5339C107.39 40.0548 107.995 37.769 107.995 36.1555C107.995 34.1386 107.054 32.5923 104.97 31.4494C103.289 30.5082 101.071 29.9704 97.373 29.2981L91.3223 28.2224C84.5321 27.0123 79.5571 26.0038 76.7334 23.4491C74.851 21.7684 73.5736 19.2809 73.5736 15.5832C73.5736 10.7427 75.5233 6.97783 79.9605 4.22141C84.5321 1.39776 90.2466 0.859924 94.9527 0.859924C104.768 0.859924 109.205 3.21296 110.281 3.81803C116.399 7.24674 116.937 12.4234 116.937 15.1799H106.315C106.247 13.3646 105.642 11.2133 102.684 9.66702C100.533 8.52411 97.4402 8.25519 94.6838 8.25519C92.3307 8.25519 89.776 8.52411 87.6246 9.59979C84.4648 11.1461 84.0615 13.3646 84.0615 14.5748C84.0615 17.3984 86.2128 18.4069 87.6919 19.0119C89.9777 19.9532 92.5996 20.2893 96.9023 21.0288L102.213 21.9028C106.583 22.6423 111.155 23.5836 114.248 25.9366C118.012 28.8275 118.685 32.5251 118.685 35.5505C118.685 42.139 115.525 45.8366 112.298 47.7863C108.264 50.2065 102.751 50.6099 97.4402 50.6099C89.8432 50.6099 84.8682 49.9376 81.0361 48.1896C74.1787 45.0298 72.8341 39.2481 72.8341 34.4075V34.2059Z"
      fill="#0889CC"
    />
    <path
      d="M50.2998 2.27155H60.7204V49.3323H50.2998V2.27155Z"
      fill="#0889CC"
    />
    <path
      d="M11.2043 22.1715H35.6759V29.9702H11.2043V49.3323H0.783691V2.27155H40.382V10.2047H11.2043V22.1715Z"
      fill="#0889CC"
    />
  </svg>
</template>
