<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0526 0.500068C12.2988 0.501337 12.4987 0.701204 12.4999 0.94739C12.5023 1.53804 12.4407 2.1184 12.3187 2.68123C11.397 2.88083 10.522 3.24198 9.72596 3.74813C9.0944 4.14969 8.54715 4.64647 8.34094 4.85268C7.23513 5.95849 6.49744 7.33064 6.17727 8.82336L6.00311 9.52074L2.94771 10.4065L0.771935 9.29349C0.596814 9.21735 0.488315 9.04159 0.501005 8.85251C0.511791 8.6628 0.641229 8.50163 0.825233 8.4496L4.05865 7.52577C4.27755 5.7625 5.06369 4.12993 6.34094 2.85268C6.54715 2.64647 7.0944 2.14969 7.72596 1.74813C9.00828 0.932797 10.4955 0.493723 12.0526 0.500068Z"
    />
    <path
      d="M15.4999 3.94739C15.4987 3.7012 15.2988 3.50134 15.0526 3.50007C13.4955 3.49372 12.0083 3.9328 10.726 4.74813C10.0944 5.14969 9.54715 5.64647 9.34094 5.85268C8.06369 7.12993 7.27755 8.7625 7.05865 10.5258L3.82523 11.4496C3.64123 11.5016 3.51179 11.6628 3.501 11.8525C3.48831 12.0416 3.59681 12.2173 3.77194 12.2935L5.82708 13.1735L6.70777 15.2293C6.73124 15.2833 6.76297 15.3302 6.80294 15.3702C6.89241 15.4596 7.0174 15.5085 7.14875 15.5003C7.33783 15.4888 7.49962 15.3588 7.55165 15.176L8.47485 11.942C10.2381 11.7231 11.8707 10.9369 13.1479 9.65968C13.3542 9.45347 13.8781 8.86147 14.2512 8.27468C15.0666 6.99236 15.5063 5.50572 15.4999 3.94739Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
