import * as Sentry from '@sentry/vue';
import { Options } from '@sentry/vue/types/types';

export default defineNuxtPlugin((nuxtApp) => {
  const vueApp = nuxtApp.vueApp;
  const router = useRouter();

  const { sentry } = useRuntimeConfig().public;

  const configuration: Partial<Options> = {
    enabled: sentry.enabled,
    app: vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [Sentry.browserTracingIntegration({ router })],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, _hint) {
      console.error('Captured Sentry Error:', event);
      return event;
    },
  };

  Sentry.init(configuration);

  if (process.client && sentry.replay.enabled && Array.isArray(configuration.integrations)) {
    Sentry.addIntegration(
      Sentry.replayIntegration({
        // mask: ['.any_selector'], // Для более тонкой настройки маскирования
        // unmask: ['.sentry-unmask'], // Для более тонкой настройки маскирования
        maskAllText: sentry.replay.maskAllText,
        blockAllMedia: sentry.replay.blockAllMedia,
        networkRequestHeaders: ['Cache-Control'],
        networkResponseHeaders: ['Referrer-Policy'],
      })
    );
  }

  Sentry.captureEvent({
    message: `Sentry init succeed, status: ${sentry.enabled ? 'enabled' : 'disabled'}`,
  });
});
