<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 3.75C1 2.23122 2.23122 1 3.75 1H8.25C9.76878 1 11 2.23122 11 3.75V5H12.25C13.7688 5 15 6.23122 15 7.75V12.25C15 13.7688 13.7688 15 12.25 15H7.75C6.23122 15 5 13.7688 5 12.25V11H3.75C2.23122 11 1 9.76878 1 8.25V3.75ZM5 9.5V7.75C5 6.23122 6.23122 5 7.75 5H9.5V3.75C9.5 3.05964 8.94036 2.5 8.25 2.5H3.75C3.05964 2.5 2.5 3.05964 2.5 3.75V8.25C2.5 8.94036 3.05964 9.5 3.75 9.5H5ZM6.5 7.75C6.5 7.05964 7.05964 6.5 7.75 6.5H12.25C12.9404 6.5 13.5 7.05964 13.5 7.75V12.25C13.5 12.9404 12.9404 13.5 12.25 13.5H7.75C7.05964 13.5 6.5 12.9404 6.5 12.25V7.75Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
