<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.82909 1.77927C3.15366 1.29243 3.70006 1 4.28518 1H10.2148C10.7999 1 11.3463 1.29243 11.6709 1.77927L13.2061 4.08205C13.3977 4.36952 13.5 4.70728 13.5 5.05277V8C13.5 8.41421 13.1642 8.75 12.75 8.75C12.3358 8.75 12 8.41421 12 8V5.5H2.5V11.75C2.5 11.8881 2.61193 12 2.75 12H8C8.41421 12 8.75 12.3358 8.75 12.75C8.75 13.1642 8.41421 13.5 8 13.5H2.75C1.7835 13.5 1 12.7165 1 11.75V5.05277C1 4.70728 1.10227 4.36952 1.29391 4.08205L2.82909 1.77927ZM4.28518 2.5C4.20159 2.5 4.12354 2.54177 4.07717 2.61132L3.15139 4H11.3486L10.4228 2.61132C10.3765 2.54177 10.2984 2.5 10.2148 2.5H4.28518ZM5 7.75C5 7.33578 5.33578 7 5.75 7H8.75C9.16421 7 9.5 7.33578 9.5 7.75C9.5 8.16421 9.16421 8.5 8.75 8.5H5.75C5.33578 8.5 5 8.16421 5 7.75ZM14.2771 11.0489C14.5743 10.7518 14.5743 10.27 14.2771 9.97286C13.98 9.67571 13.4982 9.67571 13.2011 9.97286L12.125 11.0489L11.0489 9.97286C10.7518 9.67571 10.27 9.67571 9.97286 9.97286C9.67571 10.27 9.67571 10.7518 9.97286 11.0489L11.0489 12.125L9.97286 13.2011C9.67571 13.4982 9.67571 13.98 9.97286 14.2771C10.27 14.5743 10.7518 14.5743 11.0489 14.2771L12.125 13.2011L13.2011 14.2771C13.4982 14.5743 13.98 14.5743 14.2771 14.2771C14.5743 13.98 14.5743 13.4982 14.2771 13.2011L13.2011 12.125L14.2771 11.0489Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
