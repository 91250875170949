<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.05147 2.7572C9.01711 2.63189 9.01272 2.55207 9.01582 2.50619C9.06082 2.49669 9.14047 2.48986 9.26936 2.50634C9.58436 2.54661 10.0286 2.72357 10.4617 3.09969C10.8947 3.47582 11.1322 3.89091 11.2161 4.19717C11.2505 4.32249 11.2549 4.40231 11.2518 4.44819C11.2068 4.45769 11.1271 4.46452 10.9982 4.44804C10.6832 4.40777 10.239 4.23081 9.80593 3.85468C9.37288 3.47856 9.13545 3.06346 9.05147 2.7572ZM7.86883 1.50998C8.59313 0.676043 10.1944 0.880747 11.4453 1.9672C12.6962 3.05366 13.1231 4.61045 12.3988 5.44439L12.3977 5.44561L5.84143 12.9943C5.11712 13.8282 3.5159 13.6235 2.26499 12.5371C1.01408 11.4506 0.587176 9.89384 1.31148 9.0599L7.86883 1.50998ZM3.57999 8.73552C4.02472 8.8964 4.47537 9.1588 4.88792 9.51712C5.30048 9.87543 5.6234 10.2849 5.84496 10.7027L10.1303 5.76876C9.68554 5.60789 9.23488 5.34548 8.82233 4.98717C8.40978 4.62886 8.08686 4.21939 7.8653 3.80156L3.57999 8.73552ZM2.49412 10.3071C2.45976 10.1818 2.45537 10.102 2.45847 10.0561C2.50347 10.0466 2.58312 10.0398 2.71201 10.0562C3.02701 10.0965 3.47126 10.2735 3.90432 10.6496C4.33738 11.0257 4.5748 11.4408 4.65879 11.7471C4.69315 11.8724 4.69754 11.9522 4.69444 11.9981C4.64944 12.0076 4.56979 12.0144 4.4409 11.998C4.1259 11.9577 3.68165 11.7807 3.24859 11.4046C2.81553 11.0285 2.57811 10.6134 2.49412 10.3071Z"
    />
    <circle
      cx="14"
      cy="14"
      r="1"
      transform="rotate(-180 14 14)"
    />
    <circle
      cx="12"
      cy="11"
      r="1"
      transform="rotate(-180 12 11)"
    />
    <circle
      cx="10"
      cy="14"
      r="1"
      transform="rotate(-180 10 14)"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
