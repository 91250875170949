<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.875 10C6.875 11.0355 6.03553 11.875 5 11.875C3.96447 11.875 3.125 11.0355 3.125 10C3.125 8.96447 3.96447 8.125 5 8.125C6.03553 8.125 6.875 8.96447 6.875 10ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM15 11.875C16.0355 11.875 16.875 11.0355 16.875 10C16.875 8.96447 16.0355 8.125 15 8.125C13.9645 8.125 13.125 8.96447 13.125 10C13.125 11.0355 13.9645 11.875 15 11.875Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
