<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.95711 4.29289C9.56658 3.90237 8.93342 3.90237 8.54289 4.29289C8.15237 4.68342 8.15237 5.31658 8.54289 5.70711L10.8983 8.0625H2.9375C2.41973 8.0625 2 8.48223 2 9C2 9.51777 2.41973 9.9375 2.9375 9.9375H10.8983L8.54289 12.2929C8.15237 12.6834 8.15237 13.3166 8.54289 13.7071C8.93342 14.0976 9.56658 14.0976 9.95711 13.7071L13.9571 9.70711C14.3476 9.31658 14.3476 8.68342 13.9571 8.29289L9.95711 4.29289Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
