import { singleton } from 'tsyringe';
import { AuthorizedClient } from '~/app-modules/core/clients/authorized-client';
import { HttpConnection } from '~/app-modules/core/clients/http-connection';
import { AuthService } from '~/app-modules/auth/services/auth.service';
import { UserDtoSchema } from '~/app-modules/users/clients/dto/user.dto';

@singleton()
export class UserClient extends AuthorizedClient {
  constructor(http: HttpConnection, authService: AuthService) {
    super(http, authService);
  }

  /**
   *  Получение информации о текущем юзере
   */
  async getMe() {
    return await this.executeRequest(
      {
        method: 'get',
        url: '/v1/auth/employee/current',
      },
      UserDtoSchema
    );
  }
}
