import { AuthService } from '~/app-modules/auth/services/auth.service';
import { useDialog } from '~/app-modules/core/composables/use-dialog';
import { ConfirmStatus } from '~/app-modules/core/enums/ConfirmStatus';
import CookieConfirmDialog from '~/app-modules/user-cookie-confirm/components/dialog.vue';
import { CookieConfirmClient } from '~/app-modules/user-cookie-confirm/clients/cookie-confirm.client';

const confirmationConstant = 'Согласие использования cookies платформы Fishplan принято'; // почему тут не true?

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:mounted', async () => {
    const confirmCookie = useCookie('fishplan-market-cookie-confirm');

    // 1. Если ранее принято согласие - дальнейшие проверки не требуются
    if (confirmCookie.value) return;

    const authService = useIOC(AuthService);
    const cookieConfirmClient = useIOC(CookieConfirmClient);

    // 2. Принятие куки если юзер авторизован, т.к. юзер при регистрации согласился с политикой cookies
    if (authService.isLoggedIn) {
      confirmCookie.value = confirmationConstant;
      return;
    }

    // 3. Юзер не авторизован, согласие с использованием куки не сохранено - требуется показать диалог
    const userAnswer = await useDialog().show({}, CookieConfirmDialog);
    if (userAnswer === ConfirmStatus.confirmed) {
      try {
        await cookieConfirmClient.logCookieConfirmation();
      } catch (error) {}
      confirmCookie.value = confirmationConstant;
    }
  });
});
