<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6632 4.53751C12.289 4.05205 13.1499 4.04333 13.7784 4.51436L14.2243 4.93053C14.5842 5.2003 15.0871 5.11657 15.3474 4.74353C15.6077 4.37048 15.5269 3.84937 15.1669 3.5796L14.7211 3.16344C13.5172 2.26118 11.8837 2.28391 10.6992 3.2029C10.0726 3.68906 9.20847 3.6904 8.58675 3.20806C7.38877 2.27864 5.73996 2.28709 4.54661 3.21291C3.92096 3.69831 3.0602 3.70651 2.43194 3.2347L1.77634 2.74237C1.41664 2.47224 0.913733 2.55546 0.653079 2.92824C0.392424 3.30103 0.472723 3.82222 0.832431 4.09235L1.48803 4.58468C2.69147 5.48843 4.32545 5.46703 5.51066 4.54752C6.13715 4.06148 7.00085 4.06022 7.62271 4.54267C8.82084 5.4722 10.47 5.46322 11.6632 4.53751ZM13.7784 8.51436C13.1499 8.04333 12.289 8.05205 11.6632 8.53751C10.47 9.46322 8.82084 9.4722 7.62271 8.54267C7.00085 8.06022 6.13715 8.06148 5.51066 8.54752C4.32545 9.46703 2.69147 9.48843 1.48803 8.58468L0.832431 8.09235C0.472723 7.82222 0.392424 7.30103 0.653079 6.92824C0.913733 6.55546 1.41664 6.47224 1.77634 6.74237L2.43194 7.2347C3.0602 7.70651 3.92096 7.69831 4.54661 7.21291C5.73996 6.28709 7.38877 6.27864 8.58675 7.20806C9.20847 7.6904 10.0726 7.68906 10.6992 7.2029C11.8837 6.28391 13.5172 6.26118 14.7211 7.16344L15.1669 7.5796C15.5269 7.84937 15.6077 8.37048 15.3474 8.74353C15.0871 9.11657 14.5842 9.2003 14.2243 8.93053L13.7784 8.51436ZM13.7784 12.5144C13.1499 12.0433 12.289 12.0521 11.6632 12.5375C10.47 13.4632 8.82084 13.4722 7.62271 12.5427C7.00085 12.0602 6.13715 12.0615 5.51066 12.5475C4.32545 13.467 2.69147 13.4884 1.48803 12.5847L0.832431 12.0923C0.472723 11.8222 0.392424 11.301 0.653079 10.9282C0.913733 10.5555 1.41664 10.4722 1.77634 10.7424L2.43194 11.2347C3.0602 11.7065 3.92096 11.6983 4.54661 11.2129C5.73996 10.2871 7.38877 10.2786 8.58675 11.2081C9.20847 11.6904 10.0726 11.6891 10.6992 11.2029C11.8837 10.2839 13.5172 10.2612 14.7211 11.1634L15.1669 11.5796C15.5269 11.8494 15.6077 12.3705 15.3474 12.7435C15.0871 13.1166 14.5842 13.2003 14.2243 12.9305L13.7784 12.5144Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
