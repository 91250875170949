import { Mutex } from 'async-mutex';
import axios from 'axios';
import { OrganizationsClient } from '~/app-modules/organizations/clients/organizations.client';
import { OrganizationListItemDto } from '~/app-modules/organizations/clients/dto/organization-list-item.dto';
import { RouterProvider } from '~/app-modules/core/ioc/router.provider';

const useOrganizationsStore = defineStore('OrganizationsStore', () => {
  const organizationsClient = useIOC(OrganizationsClient);
  const router = useIOC(RouterProvider).getRouter();
  const _mutex = new Mutex();

  const organizations = ref<OrganizationListItemDto[] | undefined>();

  const currentOrganization = computed(() => {
    const route = router.currentRoute.value;
    const organizationCatalogRoute = route.name === 'catalog-organizationId';
    const organizationId = route.params?.organizationId;

    if (!organizationCatalogRoute || !organizationId || !organizations.value) return;

    return organizations.value!.find((org) => org.id === organizationId);
  });

  const currentOrganizationId = computed(() => {
    return currentOrganization.value?.id;
  });

  function getOrganizationByIdSync(id: string) {
    return organizations.value?.find((org) => org.id === id);
  }

  async function loadOrganizations() {
    if (_mutex.isLocked()) return await _mutex.waitForUnlock();

    try {
      await _mutex.runExclusive(async () => {
        organizations.value = await organizationsClient.getAll();
      });
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }
    }
  }

  async function initStore() {
    if (organizations.value) {
      return;
    }

    await loadOrganizations();
  }

  return {
    organizations,
    currentOrganization,
    currentOrganizationId,
    getOrganizationByIdSync,
    loadOrganizations,
    initStore,
  };
});

@injectable()
export class OrganizationsStoreProvider {
  getStore = () => useOrganizationsStore();
}
