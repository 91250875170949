import { YandexMetrikaProvider } from '~/app-modules/metrics/services/yandex.metrika.provider';
import { ApplicationLogger } from '~/app-modules/core/logger/application-logger';
import { RouterProvider } from '~/app-modules/core/ioc/router.provider';
import { ConfigProvider } from '~/app-modules/core/ioc/config.provider';
import { UserDto } from '~/app-modules/users/clients/dto/user.dto';
import { BaseEvent } from '~/app-modules/metrics/services/events/base.event';
import { UsersStoreProvider } from '~/app-modules/users/stores/users.store';

// client side only solution
@singleton()
export class MetricsService {
  constructor(
    private _userStoreProvider: UsersStoreProvider,
    private _logger: ApplicationLogger,
    private _routerProvider: RouterProvider,
    private _yandexProvider: YandexMetrikaProvider,
    configProvider: ConfigProvider
  ) {
    const { yandexMetrika } = configProvider.getPublicRuntimeConfig();
    try {
      this._isMetricsEnabled = ensureValidBool({
        isMetricsEnabled: yandexMetrika.enabled,
      });
    } catch (e) {
      this._logger.error('Metrics service not initialized: isMetricsEnabled has invalid value', e);
      this._isMetricsEnabled = false;
    }
    this._init();
  }

  private readonly _isMetricsEnabled: boolean;
  private _isReady = false;

  public trackEvent(event: BaseEvent) {
    if (this._isMetricsEnabled) {
      this._yandexProvider.trackEvent(event);
    } else {
      this._logger.log(`Metrics is disabled, event '${event.type}' is not tracked`);
    }
  }

  private async _init() {
    await this._routerProvider.getRouter().isReady();
    if (!this._isReady) {
      if (this._isMetricsEnabled) {
        this._yandexProvider.init();
      }

      this._subscribeForRouteChanges();
      this._subscribeForUserData();

      this._isReady = true;
    }
  }

  private _subscribeForRouteChanges() {
    this._routerProvider.getRouter().afterEach((to, from) => {
      if (to.fullPath === from.fullPath) {
        return;
      }
      if (this._isMetricsEnabled) {
        this._yandexProvider.trackNavigation(from.fullPath, to.fullPath);
      } else {
        this._logger.log(
          `Metrics is disabled, navigation from  '${from.fullPath}' to '${to.fullPath}' is not tracked`
        );
      }
    });
  }

  private _subscribeForUserData() {
    this._userStoreProvider.getStore().subscribeUserChanged((currentUser: UserDto | undefined) => {
      if (!currentUser) {
        return;
      }

      if (this._isMetricsEnabled) {
        this._yandexProvider.trackSignedUser(currentUser);
      } else {
        this._logger.log('Metrics is disabled, user is not tracked');
      }
    });
  }
}
