import { singleton } from 'tsyringe';
import { NotAuthorizedClient } from '~/app-modules/core/clients/not-authorized-client';
import { HttpConnection } from '~/app-modules/core/clients/http-connection';

@singleton()
export class CookieConfirmClient extends NotAuthorizedClient {
  constructor(connection: HttpConnection) {
    super(connection);
  }

  /**
   * Логирование подтверждения принятия cookie
   */
  async logCookieConfirmation() {
    return await this.executeRequest({
      method: 'post',
      url: '/v1/cookie-confirmation',
    });
  }
}
