<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.9125 12.2325L40.3725 19.915C39.6375 23.59 36.4 26.25 32.655 26.25H10.535C10.9725 29.2425 13.5625 31.5 16.59 31.5H34.125C34.615 31.5 35 31.885 35 32.375C35 32.865 34.615 33.25 34.125 33.25H16.59C12.6875 33.25 9.3275 30.3275 8.785 26.46L5.67 4.0075C5.495 2.7125 4.375 1.75 3.0625 1.75H0.875C0.385 1.75 0 1.365 0 0.875C0 0.385 0.385 0 0.875 0H3.08C5.25 0 7.1225 1.6275 7.42 3.78L7.875 7H30.0095C30.4995 7 30.8845 7.385 30.8845 7.875C30.8845 8.365 30.4995 8.75 30.0095 8.75H8.1025L10.29 24.5H32.655C35.56 24.5 38.0975 22.435 38.6575 19.5825L40.1975 11.9C40.355 11.13 40.145 10.325 39.655 9.7125C39.1475 9.1 38.4125 8.75 37.625 8.75H28.875C28.385 8.75 28 8.365 28 7.875C28 7.385 28.385 7 28.875 7H37.625C38.9375 7 40.18 7.5775 41.0025 8.61C41.8425 9.625 42.175 10.955 41.9125 12.25V12.2325ZM15.75 38.5C15.75 40.425 14.175 42 12.25 42C10.325 42 8.75 40.425 8.75 38.5C8.75 36.575 10.325 35 12.25 35C14.175 35 15.75 36.575 15.75 38.5ZM14 38.5C14 37.5375 13.2125 36.75 12.25 36.75C11.2875 36.75 10.5 37.5375 10.5 38.5C10.5 39.4625 11.2875 40.25 12.25 40.25C13.2125 40.25 14 39.4625 14 38.5ZM33.25 38.5C33.25 40.425 31.675 42 29.75 42C27.825 42 26.25 40.425 26.25 38.5C26.25 36.575 27.825 35 29.75 35C31.675 35 33.25 36.575 33.25 38.5ZM31.5 38.5C31.5 37.5375 30.7125 36.75 29.75 36.75C28.7875 36.75 28 37.5375 28 38.5C28 39.4625 28.7875 40.25 29.75 40.25C30.7125 40.25 31.5 39.4625 31.5 38.5Z"
      stroke="currentColor"
      stroke-width="1.5px"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
