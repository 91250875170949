<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12ZM7.15211 9.43279C7.19272 9.78455 7.50832 10.0243 7.86241 10.0243C8.35417 10.0243 8.68009 9.57435 8.67358 9.08264C8.67053 8.85293 8.71179 8.65937 8.81601 8.44969C8.87267 8.29783 9.02322 8.14975 9.15098 8.02408C9.17745 7.99805 9.20293 7.97298 9.2264 7.94904C9.36594 7.80951 9.54378 7.67544 9.75992 7.54685C10.0171 7.39364 10.2401 7.22264 10.4289 7.03386C10.6176 6.84234 10.764 6.62346 10.868 6.37722C10.9747 6.12825 11.028 5.84371 11.028 5.5236C11.028 5.0448 10.909 4.63577 10.671 4.29651C10.433 3.95725 10.1047 3.6987 9.68605 3.52086C9.26744 3.34029 8.78591 3.25 8.24145 3.25C7.74624 3.25 7.29343 3.33892 6.88304 3.51676C6.47264 3.69186 6.14295 3.95862 5.89398 4.31703C5.78804 4.46838 5.70339 4.63707 5.64005 4.8231C5.48757 5.27093 5.8899 5.67134 6.36298 5.67134H6.58099C6.91219 5.67134 7.15371 5.38389 7.33858 5.1091C7.43981 4.95588 7.56977 4.84097 7.72845 4.76436C7.88714 4.68502 8.0554 4.64535 8.23324 4.64535C8.41655 4.64535 8.58345 4.68365 8.73393 4.76026C8.88714 4.83687 9.00889 4.94631 9.09918 5.08858C9.18947 5.23085 9.23461 5.39637 9.23461 5.58516C9.23461 5.763 9.19494 5.92442 9.11559 6.06943C9.03625 6.2117 8.92955 6.34166 8.79549 6.4593C8.66142 6.57695 8.50958 6.69049 8.33995 6.79993C8.11286 6.9422 7.87168 7.10103 7.71026 7.27613C7.54884 7.45123 7.35991 7.75385 7.2606 8.03367C7.11506 8.44377 7.09691 8.95469 7.15211 9.43279Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
