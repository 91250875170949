<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 3.75V5.75V8.75V10.75C2 11.7165 2.7835 12.5 3.75 12.5H7C7.82843 12.5 8.5 11.8284 8.5 11V9.5H9.75C10.1642 9.5 10.5 9.16421 10.5 8.75C10.5 8.33579 10.1642 8 9.75 8H8.5V6.5H12V7C12 7.41421 12.3358 7.75 12.75 7.75C13.1642 7.75 13.5 7.41421 13.5 7V5.75V3.75C13.5 2.7835 12.7165 2 11.75 2H7.75H3.75C2.7835 2 2 2.7835 2 3.75ZM7 8V6.5H3.5V8H7ZM3.5 9.5V10.75C3.5 10.8881 3.61193 11 3.75 11H7V9.5H3.5ZM3.75 3.5C3.61193 3.5 3.5 3.61193 3.5 3.75V5H7V3.5H3.75ZM12 5H8.5V3.5H11.75C11.8881 3.5 12 3.61193 12 3.75V5ZM15.2803 13.2803C15.5732 12.9874 15.5732 12.5126 15.2803 12.2197C14.9874 11.9268 14.5125 11.9268 14.2197 12.2197L13.5 12.9393V9.75C13.5 9.33579 13.1642 9 12.75 9C12.3358 9 12 9.33579 12 9.75V12.9393L11.2803 12.2197C10.9874 11.9268 10.5125 11.9268 10.2197 12.2197C9.92676 12.5126 9.92676 12.9874 10.2197 13.2803L12.0429 15.1036C12.4334 15.4941 13.0666 15.4941 13.4571 15.1036L15.2803 13.2803Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
