import { z } from 'zod';
import { OrganizationStatuses } from '~/app-modules/organizations/enums/organization-statuses';
import { PaymentType } from '~/app-modules/organizations/enums/payment-type';

export const DocumentDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type DocumentDto = z.infer<typeof DocumentDtoSchema>;

export const CurrentOrganizationDtoSchema = z.object({
  status: z.nativeEnum(OrganizationStatuses),
  customStatus: z.string().nullable(),
  isVatPayer: z.boolean(),
  phoneNumber: z.string(),
  email: z.string(),
  paymentTypes: z.array(z.nativeEnum(PaymentType)),
  description: z.string(),
  logoId: z.string().nullable(),
  documents: z.array(DocumentDtoSchema),
});

export type CurrentOrganizationDto = z.infer<typeof CurrentOrganizationDtoSchema>;

export const OrganizationDtoSchema = CurrentOrganizationDtoSchema.merge(
  z.object({
    id: z.string(),
    name: z.string(),
    businessAddress: z.string().nullable(),
  })
);

export type OrganizationDto = z.infer<typeof OrganizationDtoSchema>;
