<template>
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.92923 0.964004C6.30272 0.345331 7.19728 0.345332 7.57077 0.964005L9.07701 3.45904C9.21064 3.6804 9.42746 3.83846 9.67856 3.89758L12.5089 4.56397C13.2107 4.72921 13.4871 5.58289 13.0161 6.13049L11.1167 8.33889C10.9482 8.53482 10.8654 8.79057 10.887 9.04847L11.13 11.9554C11.1902 12.6762 10.4665 13.2038 9.80192 12.9235L7.12178 11.7934C6.884 11.6931 6.616 11.6931 6.37822 11.7934L3.69808 12.9235C3.03351 13.2038 2.30979 12.6762 2.37005 11.9554L2.61304 9.04847C2.6346 8.79057 2.55178 8.53482 2.38327 8.33889L0.483854 6.13049C0.0128729 5.58289 0.289309 4.72921 0.991119 4.56397L3.82144 3.89758C4.07254 3.83846 4.28936 3.6804 4.42299 3.45904L5.92923 0.964004Z"
      fill="#F2B049"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
