<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.29289 4.29289C6.68342 3.90237 7.31658 3.90237 7.70711 4.29289C8.09763 4.68342 8.09763 5.31658 7.70711 5.70711L5.35171 8.0625H13.3125C13.8303 8.0625 14.25 8.48223 14.25 9C14.25 9.51777 13.8303 9.9375 13.3125 9.9375H5.35171L7.70711 12.2929C8.09763 12.6834 8.09763 13.3166 7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071L2.29289 9.70711C1.90237 9.31658 1.90237 8.68342 2.29289 8.29289L6.29289 4.29289Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
