<template>
  <div class="background">
    <div class="header" />
    <svg
      class="waves"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 20 150 60"
      preserveAspectRatio="none"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        />
      </defs>
      <g class="parallax">
        <use
          xlink:href="#gentle-wave"
          x="48"
          y="0"
          fill="rgba(70,146,193,0.7)"
        />
        <use
          xlink:href="#gentle-wave"
          x="48"
          y="3"
          fill="rgba(70,146,193,0.3)"
        />
        <use
          xlink:href="#gentle-wave"
          x="48"
          y="5"
          fill="rgba(70,146,193,0.7)"
        />
        <use
          xlink:href="#gentle-wave"
          x="48"
          y="7"
          fill="rgba(70,146,193,0.5)"
        />
      </g>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.background {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  background-blend-mode: color-burn;
  background-image: url('/gray-waves-background.svg');
  background-color: rgb(var(--v-theme-beton4));
}

.waves {
  position: fixed;
  width: 100%;
  max-height: 250px;
  min-height: 150px;
  background-blend-mode: color-burn;
}

.parallax > use {
  animation: move-forever 60s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  z-index: 0;
  animation-delay: -2s;
  animation-duration: 15s;
}

.parallax > use:nth-child(2) {
  z-index: 1;
  animation-delay: -50s;
  animation-duration: 19s;
}

.parallax > use:nth-child(3) {
  z-index: 2;
  animation-delay: -33s;
  animation-duration: 23s;
}

.parallax > use:nth-child(4) {
  z-index: 3;
  animation-delay: -17s;
  animation-duration: 27s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 100px;
    min-height: 50px;
  }
}
</style>
