<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9999 2.44739C12.9987 2.2012 12.7988 2.00134 12.5526 2.00007C10.9955 1.99372 9.50828 2.4328 8.22596 3.24813C7.5944 3.64969 7.04715 4.14647 6.84094 4.35268C5.56369 5.62993 4.77755 7.2625 4.55865 9.02577L1.32523 9.9496C1.14123 10.0016 1.01179 10.1628 1.001 10.3525C0.988315 10.5416 1.09681 10.7173 1.27194 10.7935L3.32708 11.6735L4.20777 13.7293C4.23124 13.7833 4.26297 13.8302 4.30294 13.8702C4.39241 13.9596 4.5174 14.0085 4.64875 14.0003C4.83783 13.9888 4.99962 13.8588 5.05165 13.676L5.97485 10.442C7.73813 10.2231 9.3707 9.43693 10.6479 8.15968C10.8542 7.95347 11.3781 7.36147 11.7512 6.77468C12.5666 5.49236 13.0063 4.00572 12.9999 2.44739Z"
    />
    <path
      d="M12 9C12.4142 9 12.75 9.33579 12.75 9.75V11.25H14.25C14.6642 11.25 15 11.5858 15 12C15 12.4142 14.6642 12.75 14.25 12.75H12.75V14.25C12.75 14.6642 12.4142 15 12 15C11.5858 15 11.25 14.6642 11.25 14.25V12.75H9.75C9.33579 12.75 9 12.4142 9 12C9 11.5858 9.33579 11.25 9.75 11.25H11.25V9.75C11.25 9.33579 11.5858 9 12 9Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
