<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5858 3.88215C14.5921 3.88519 14.5984 3.88832 14.6046 3.89153C14.831 4.00856 14.98 4.23946 14.9981 4.4979C14.9981 4.47997 14.9994 4.51561 14.9981 4.4979L15 11.688C15 12.3518 14.6295 12.9565 14.0476 13.2427L8.53125 15.8785C8.30888 15.9879 7.99983 16 7.99983 16C7.99983 16 7.69531 16 7.45442 15.8785L1.96643 13.2432C1.37685 12.96 1 12.3511 1 11.6817V4.55138C1 4.29898 1.12527 4.06406 1.33216 3.92848C1.30205 3.94261 1.36052 3.9099 1.33216 3.92848L6.88721 1.25404C7.59258 0.91532 8.40749 0.91532 9.11286 1.25404L14.5858 3.88215ZM8.50587 2.59247C8.18524 2.43851 7.81483 2.43851 7.4942 2.59247L3.41487 4.55137L7.99983 6.75307L12.5852 4.55135L8.50587 2.59247ZM8.71772 8.03845V14.1659L13.4286 11.9101C13.5117 11.8692 13.5646 11.7829 13.5646 11.688V5.71096L8.71772 8.03845ZM2.43537 5.71097V11.6817C2.43537 11.7773 2.48921 11.8643 2.57343 11.9047L7.28228 14.1659V8.03845L2.43537 5.71097Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
