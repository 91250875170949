<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6995 3C14.2518 3 14.6995 2.55228 14.6995 2C14.6995 1.44772 14.2518 1 13.6995 1C13.1472 1 12.6995 1.44772 12.6995 2C12.6995 2.55228 13.1472 3 13.6995 3ZM10.6995 4C10.6995 4.55228 10.2518 5 9.69953 5C9.14724 5 8.69953 4.55228 8.69953 4C8.69953 3.44772 9.14724 3 9.69953 3C10.2518 3 10.6995 3.44772 10.6995 4ZM13.6995 7C14.2518 7 14.6995 6.55228 14.6995 6C14.6995 5.44772 14.2518 5 13.6995 5C13.1472 5 12.6995 5.44772 12.6995 6C12.6995 6.55228 13.1472 7 13.6995 7ZM10.7184 7.00007C10.9646 7.00134 11.1644 7.2012 11.1657 7.44739C11.1705 8.61033 10.3029 11.2171 9.70462 12.5673C9.31152 12.5828 8.91529 12.4629 8.58675 12.2081C7.38876 11.2786 5.73996 11.2871 4.54661 12.2129C4.21318 12.4716 3.81297 12.5947 3.41677 12.5819C3.23013 12.521 3.04436 12.4816 2.86463 12.4724C2.77248 12.4378 2.68257 12.395 2.59595 12.3441C3.08325 11.5959 4.52605 9.83336 5.00673 9.35268C5.21294 9.14647 5.76019 8.64969 6.39174 8.24813C7.67407 7.4328 9.16133 6.99372 10.7184 7.00007ZM11.6632 14.5375C12.289 14.0521 13.1499 14.0433 13.7784 14.5144L14.2243 14.9305C14.5842 15.2003 15.0871 15.1166 15.3474 14.7435C15.6077 14.3705 15.5269 13.8494 15.1669 13.5796L14.7211 13.1634C13.5172 12.2612 11.8837 12.2839 10.6992 13.2029C10.0726 13.6891 9.20847 13.6904 8.58675 13.2081C7.38877 12.2786 5.73996 12.2871 4.54661 13.2129C3.92096 13.6983 3.0602 13.7065 2.43194 13.2347L1.77634 12.7424C1.41664 12.4722 0.913733 12.5555 0.653079 12.9282C0.392424 13.301 0.472723 13.8222 0.832431 14.0923L1.48803 14.5847C2.69147 15.4884 4.32545 15.467 5.51066 14.5475C6.13715 14.0615 7.00085 14.0602 7.62271 14.5427C8.82084 15.4722 10.47 15.4632 11.6632 14.5375Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
