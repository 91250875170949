import MobileDetect from 'mobile-detect';

export default defineNuxtPlugin(() => {
  const headers = useRequestHeaders();

  const mobileDetect = process.server
    ? new MobileDetect(headers['user-agent'])
    : new MobileDetect(navigator.userAgent);

  const isMobile = mobileDetect.phone() !== null || mobileDetect.mobile() === 'UnknownMobile';
  const isTablet = mobileDetect.tablet() !== null || mobileDetect.mobile() === 'UnknownTablet';

  const userAgentBreakpoint = computed(() => {
    switch (true) {
      case isMobile:
        return 'xs';
      case isTablet:
        return 'md';
      default:
        return 'lg';
    }
  });

  return {
    provide: {
      userAgentBreakpoint,
    },
  };
});
