<template>
  <svg
    height="100%"
    width="100%"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 47 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.8223 0.938477H1.22949V59.9224H45.831V14.1089M32.8223 0.938477V14.1089H45.831M32.8223 0.938477L45.831 14.1089"
      stroke="#999999"
    />
    <path
      d="M40.0391 30.9873H6.2334V54.7983H40.0391V30.9873Z"
      stroke="#207BCB"
    />
    <path
      d="M15.5817 41.9227L7.87988 53.0345H38.8047L31.1029 41.9227L27.9869 46.097L23.4011 38.8655L18.4037 46.097L15.5817 41.9227Z"
      fill="#207BCB"
    />
    <circle
      cx="34.4543"
      cy="36.749"
      r="2.93965"
      fill="#207BCB"
    />
  </svg>
</template>
<script setup lang="ts"></script>
