<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 0.999878C1.94772 0.999878 1.5 1.44759 1.5 1.99988V2.99988H14.5V1.99988C14.5 1.44759 14.0523 0.999878 13.5 0.999878H2.5Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 3.99988H14L13.1528 13.166C13.0664 14.2026 12.1999 14.9999 11.1597 14.9999H4.84027C3.80009 14.9999 2.93356 14.2026 2.84717 13.166L2 3.99988ZM5.87868 6.87856C6.17157 6.58566 6.64645 6.58566 6.93934 6.87856L8 7.93922L9.06066 6.87856C9.35355 6.58566 9.82843 6.58566 10.1213 6.87856C10.4142 7.17145 10.4142 7.64632 10.1213 7.93922L9.06066 8.99988L10.1213 10.0605C10.4142 10.3534 10.4142 10.8283 10.1213 11.1212C9.82843 11.4141 9.35355 11.4141 9.06066 11.1212L8 10.0605L6.93934 11.1212C6.64645 11.4141 6.17157 11.4141 5.87868 11.1212C5.58579 10.8283 5.58579 10.3534 5.87868 10.0605L6.93934 8.99988L5.87868 7.93922C5.58579 7.64632 5.58579 7.17145 5.87868 6.87856Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
