import { ConfigProvider } from '~/app-modules/core/ioc/config.provider';

@singleton()
export class ApplicationLogger {
  constructor(configProvider: ConfigProvider) {
    const { logger } = configProvider.getPublicRuntimeConfig();
    this._logToConsole = logger.logToConsole;
  }

  private readonly _logToConsole: boolean;

  public log(message?: any, ...optionalParams: any[]) {
    if (this._logToConsole) {
      console.log(message, optionalParams);
    }
  }

  public error(message?: any, ...optionalParams: any[]) {
    if (this._logToConsole) {
      console.error(message, optionalParams);
    }
  }
}
