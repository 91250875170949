export default defineNuxtRouteMiddleware((_to, _from) => {
  // TODO: Разработать claims guard
});

enum ACTION {}

enum SUBJECT {}

type Claim = [SUBJECT, ACTION];

declare module '#app' {
  interface PageMeta {
    claims?: Claim[];
  }
}

declare module 'vue-router' {
  interface RouteMeta {
    claims?: Claim[];
  }
}
