<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7709 3.99032C15.0097 3.99155 15.2036 4.18543 15.2048 4.42424C15.211 5.93589 14.7845 7.37799 13.9935 8.6219C13.6316 9.19111 13.1233 9.76537 12.9233 9.96541C11.6843 11.2044 10.1007 11.967 8.39021 12.1793L7.49467 15.3165C7.4442 15.4938 7.28725 15.6199 7.10383 15.631C6.97642 15.639 6.85517 15.5916 6.76839 15.5048C6.72961 15.4661 6.69884 15.4205 6.67606 15.3682L5.82176 13.374L3.82818 12.5203C3.6583 12.4465 3.55306 12.276 3.56536 12.0925C3.57583 11.9085 3.70139 11.7522 3.87988 11.7017L7.01643 10.8056C7.22877 9.0951 7.99137 7.51144 9.23035 6.27245C9.43039 6.07242 9.96124 5.59052 10.5739 5.20099C11.8178 4.41008 13.2605 3.98416 14.7709 3.99032Z"
    />
    <path
      d="M2.34615 0C1.87034 0 1.48462 0.366573 1.48462 0.818763V1.33049C1.48462 1.78268 1.87034 2.14925 2.34615 2.14925H2.79786C2.90525 2.14925 2.99231 2.23149 2.99231 2.33293V2.79348C2.99231 2.89492 2.90525 2.97715 2.79786 2.97715H2.29231C1.57859 2.97715 1 3.15967 1 3.83795V8.77186C1 9.45014 1.57859 10 2.29231 10H6.16791C6.462 8.62002 7.08394 7.33298 8 6.23804V4.2053C8 3.52701 7.42141 2.97715 6.70769 2.97715H6.20214C6.09475 2.97715 6.00769 2.89492 6.00769 2.79348V2.33293C6.00769 2.23149 6.09475 2.14925 6.20214 2.14925H6.65385C7.12966 2.14925 7.51539 1.78268 7.51539 1.33049V0.818763C7.51539 0.366573 7.12966 0 6.65385 0H2.34615Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
