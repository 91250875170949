<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 3.5C2.86193 3.5 2.75 3.61193 2.75 3.75V6.75C2.75 6.88807 2.86193 7 3 7H11.75C10.8684 7 10.139 7.65193 10.0177 8.5H3C2.0335 8.5 1.25 7.7165 1.25 6.75V3.75C1.25 2.7835 2.0335 2 3 2H13C13.9665 2 14.75 2.7835 14.75 3.75V6.75C14.75 6.8942 14.7326 7.03433 14.6997 7.16839C14.4037 7.05947 14.0838 7 13.75 7H13C13.1381 7 13.25 6.88807 13.25 6.75V3.75C13.25 3.61193 13.1381 3.5 13 3.5H3ZM9.09796 11.1713C9.0345 11.3524 9 11.5472 9 11.75C9 12.086 9.09471 12.3999 9.2589 12.6665L4.00777 13.6167C3.05671 13.7887 2.14623 13.1573 1.97414 12.2062L1.43998 9.25414C1.42431 9.16754 1.41531 9.08128 1.4126 8.99588C1.86102 9.3134 2.40871 9.5 2.99998 9.5H3.00883L3.45017 11.9391C3.47475 12.075 3.60482 12.1652 3.74069 12.1406L9.09796 11.1713Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.75 8C11.3358 8 11 8.33579 11 8.75V11H10.75C10.3358 11 10 11.3358 10 11.75C10 12.1642 10.3358 12.5 10.75 12.5H11V13H10.75C10.3358 13 10 13.3358 10 13.75C10 14.1642 10.3358 14.5 10.75 14.5H11V14.75C11 15.1642 11.3358 15.5 11.75 15.5C12.1642 15.5 12.5 15.1642 12.5 14.75V14.5H12.75C13.1642 14.5 13.5 14.1642 13.5 13.75C13.5 13.3358 13.1642 13 12.75 13H12.5V12.5H13.75C14.7165 12.5 15.5 11.7165 15.5 10.75V9.75C15.5 8.7835 14.7165 8 13.75 8H11.75ZM12.5 11H13.75C13.8881 11 14 10.8881 14 10.75V9.75C14 9.61193 13.8881 9.5 13.75 9.5H12.5V11Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
