import { createVNode, render, DefineComponent } from 'vue';
import { DialogOptions } from '~/app-modules/core/composables/dialog-options-type';

type VueComponent = DefineComponent<any, any, any>;

export function useDialog() {
  /**
   *     Внимание! Компонент, передаваемый в метод демонстрации диалогового окна, должен содержать:
   *     - метод open():Promise<any>;
   *     - экспорт метода: defineExpose({open}
   *     <p> Также см. пример реализации в уже существующих компонентах.
   */
  async function show<T>(options: DialogOptions = {}, component: VueComponent, formData?: T) {
    return await showDialog(options, component, formData);
  }

  async function showDialog<T>(options: DialogOptions, component: VueComponent, formData?: T) {
    const dialogRef = ref<InstanceType<typeof component> | null>();
    const container = document.createElement('div') as Element;
    const Component = getComponentWrapper(component, options, dialogRef);

    const vnode = createVNode(Component);
    vnode.appContext = useNuxtApp().vueApp._context;

    render(vnode, container);

    document.body.appendChild(container);

    const result = await dialogRef.value?.open(formData);

    /**
     * graceful unmount
     *
     * https://github.com/vuejs/core/issues/593#issuecomment-572442459
     */
    render(null, container);
    container.remove();
    return result;
  }

  function getComponentWrapper(Component: VueComponent, props: DialogOptions, ref: Ref) {
    return defineComponent({
      setup() {
        return () => (
          <Component
            ref={ref}
            {...props}
          />
        );
      },
    });
  }

  return { show };
}
