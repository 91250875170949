<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.25 1.5C6.72553 1.5 5.3177 1.73805 4.26795 2.14441C3.74655 2.34624 3.27652 2.60363 2.92314 2.92705C2.57727 3.24358 2.25 3.71477 2.25 4.33871V12.6613C2.25 13.2852 2.57727 13.7564 2.92314 14.073C3.27652 14.3964 3.74656 14.6538 4.26796 14.8556C5.31772 15.262 6.72554 15.5 8.25 15.5C9.77446 15.5 11.1823 15.262 12.232 14.8556C12.7534 14.6538 13.2235 14.3964 13.5769 14.073C13.9227 13.7564 14.25 13.2852 14.25 12.6613V4.33871C14.25 3.71477 13.9227 3.24358 13.5769 2.92705C13.2235 2.60363 12.7534 2.34624 12.2321 2.14441C11.1823 1.73805 9.77447 1.5 8.25 1.5ZM3.74844 4.14317C3.63954 4.24283 3.60089 4.3092 3.58792 4.33871C3.60089 4.36822 3.63954 4.43459 3.74844 4.53425C3.93482 4.70483 4.24583 4.8923 4.68958 5.06408C5.57011 5.40493 6.82895 5.62903 8.25 5.62903C9.67105 5.62903 10.9299 5.40493 11.8104 5.06408C12.2542 4.8923 12.5652 4.70483 12.7516 4.53425C12.8605 4.43459 12.8991 4.36822 12.9121 4.33871C12.8991 4.3092 12.8605 4.24283 12.7516 4.14317C12.5652 3.97259 12.2542 3.78511 11.8104 3.61334C10.9299 3.27249 9.67105 3.04839 8.25 3.04839C6.82895 3.04839 5.57011 3.27249 4.68958 3.61334C4.24583 3.78511 3.93482 3.97259 3.74844 4.14317ZM12.9167 6.21595C12.7038 6.33423 12.4734 6.43957 12.2321 6.53301C11.1823 6.93937 9.77447 7.17742 8.25 7.17742C6.72553 7.17742 5.3177 6.93937 4.26795 6.53301C4.02658 6.43957 3.79621 6.33423 3.58333 6.21595L3.58238 12.6464C3.58238 12.6464 3.62114 12.7403 3.74844 12.8569C3.93482 13.0274 4.24584 13.2149 4.68959 13.3867C5.57012 13.7275 6.82896 13.9516 8.25 13.9516C9.67104 13.9516 10.9299 13.7275 11.8104 13.3867C12.2542 13.2149 12.5652 13.0274 12.7516 12.8569C12.8789 12.7403 12.9176 12.6464 12.9176 12.6464L12.9167 6.21595Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
