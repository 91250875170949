<template>
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.63343 0.808763C9.275 0.480209 8.72488 0.480209 8.36645 0.808763L0.866511 7.68371C0.48484 8.03358 0.459056 8.6266 0.808922 9.00827C1.07076 9.29391 1.46879 9.38022 1.81249 9.25892V15.2497C1.81249 15.7675 2.23222 16.1872 2.74998 16.1872H7.12495C7.64271 16.1872 8.06244 15.7675 8.06244 15.2497V11.1873H9.93743V15.2497C9.93743 15.7675 10.3572 16.1872 10.8749 16.1872H15.2499C15.7676 16.1872 16.1874 15.7675 16.1874 15.2497V9.25892C16.5311 9.38023 16.9291 9.29392 17.191 9.00827C17.5408 8.6266 17.515 8.03358 17.1334 7.68371L9.63343 0.808763ZM14.318 7.64652L8.99994 2.77161L3.68185 7.64653C3.68557 7.68044 3.68748 7.71489 3.68748 7.74979V14.3122H6.18746V10.2498C6.18746 9.73201 6.60719 9.31228 7.12495 9.31228H10.8749C11.3927 9.31228 11.8124 9.73201 11.8124 10.2498V14.3122H14.3124V7.74979C14.3124 7.71489 14.3143 7.68043 14.318 7.64652Z"
      fill="#3C9173"
      fill-opacity="0.64"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
