<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 2.74988C9 2.33566 8.66421 1.99988 8.25 1.99988H4.75C3.23122 1.99988 2 3.23109 2 4.74988V11.2499C2 12.7687 3.23122 13.9999 4.75 13.9999H8.25C8.66421 13.9999 9 13.6641 9 13.2499C9 12.8357 8.66421 12.4999 8.25 12.4999H4.75C4.05964 12.4999 3.5 11.9402 3.5 11.2499V4.74988C3.5 4.05952 4.05964 3.49988 4.75 3.49988H8.25C8.66421 3.49988 9 3.16409 9 2.74988Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3657 4.23419C11.0533 3.92177 10.5467 3.92177 10.2343 4.23419C9.9219 4.54661 9.9219 5.05314 10.2343 5.36556L12.1186 7.24988H5.75C5.33579 7.24988 5 7.58566 5 7.99988C5 8.41409 5.33579 8.74988 5.75 8.74988H12.1186L10.2343 10.6342C9.9219 10.9466 9.9219 11.4531 10.2343 11.7656C10.5467 12.078 11.0533 12.078 11.3657 11.7656L14.5657 8.56556C14.8781 8.25314 14.8781 7.74661 14.5657 7.43419L11.3657 4.23419Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
