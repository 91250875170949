<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 10.5V13.5H12.5V10.5H9.5ZM9 9C8.44772 9 8 9.44772 8 10V14C8 14.5523 8.44772 15 9 15H13C13.5523 15 14 14.5523 14 14V10C14 9.44772 13.5523 9 13 9H9Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.5 10.5V13.5H5.5V10.5H2.5ZM2 9C1.44772 9 1 9.44772 1 10V14C1 14.5523 1.44772 15 2 15H6C6.55228 15 7 14.5523 7 14V10C7 9.44772 6.55228 9 6 9H2Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.5 3.5V6.5H5.5V3.5H2.5ZM2 2C1.44772 2 1 2.44772 1 3V7C1 7.55228 1.44772 8 2 8H6C6.55228 8 7 7.55228 7 7V3C7 2.44772 6.55228 2 6 2H2Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 3.5V6.5H12.5V3.5H9.5ZM9 2C8.44772 2 8 2.44772 8 3V7C8 7.55228 8.44772 8 9 8H13C13.5523 8 14 7.55228 14 7V3C14 2.44772 13.5523 2 13 2H9Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
