import { Mutex } from 'async-mutex';
import axios from 'axios';
import { AuthService } from '~/app-modules/auth/services/auth.service';
import { UserClient } from '~/app-modules/users/clients/users.client';
import { type UserDto } from '~/app-modules/users/clients/dto/user.dto';

const useUsersStore = defineStore('UsersStore', () => {
  const authService = useIOC(AuthService);
  const userClient = useIOC(UserClient);

  const _mutex = new Mutex();
  const currentUser = ref<UserDto | undefined>();

  watch(
    () => authService.isLoggedIn,
    async () => {
      // авторизация потеряна
      if (!authService.isLoggedIn) {
        currentUser.value = undefined;
      } else if (authService.isLoggedIn && !currentUser.value) {
        // авторизация получена
        await loadUser();
      }
    }
  );

  const fullName = computed(() => {
    if (!currentUser.value) return '';

    const { lastName, firstName, middleName } = currentUser.value;
    return `${lastName} ${firstName}${middleName ? ` ${middleName}` : ''}`;
  });

  const organizationName = computed(() => {
    return currentUser.value?.organizationName || '';
  });

  async function loadUser() {
    if (_mutex.isLocked()) return await _mutex.waitForUnlock();

    if (!authService.isLoggedIn) return;

    try {
      await _mutex.runExclusive(async () => {
        currentUser.value = await userClient.getMe();
      });
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }
    }
  }

  async function initStore() {
    // client side init
    if (currentUser.value) {
      return;
    }

    // server side init
    await loadUser();
  }

  function subscribeUserChanged(onUserChanged: (user: UserDto | undefined) => void) {
    return watchEffect(() => {
      onUserChanged(currentUser.value);
    });
  }

  return {
    currentUser,
    fullName,
    organizationName,
    loadUser,
    initStore,
    subscribeUserChanged,
  };
});

@injectable()
export class UsersStoreProvider {
  getStore = () => useUsersStore();
}
