import { z } from 'zod';
import { OrganizationStatuses } from '~/app-modules/organizations/enums/organization-statuses';
import { PaymentType } from '~/app-modules/organizations/enums/payment-type';

export const OrganizationListItemDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
  status: z.nativeEnum(OrganizationStatuses),
  customStatus: z.string().nullable(),
  isVatPayer: z.boolean(),
  phoneNumber: z.string(),
  email: z.string(),
  paymentTypes: z.array(z.nativeEnum(PaymentType)),
  description: z.string(),
  businessAddress: z.string().nullable(),
  logoId: z.string().nullable(),
});

export type OrganizationListItemDto = z.infer<typeof OrganizationListItemDtoSchema>;
