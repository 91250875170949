import { z } from 'zod';
import { UiDictionaryType } from '~/app-modules/core/enums/UiDictionaryType';

const dictionaryItemDtoSchema = z.tuple([z.string(), z.string()]);

export const UiDictionariesDtoSchema = z.record(
  z.nativeEnum(UiDictionaryType),
  z.array(dictionaryItemDtoSchema)
);

export type UiDictionariesDto = z.infer<typeof UiDictionariesDtoSchema>;
