<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.41414 2.99999C4.02361 2.60946 3.39045 2.60946 2.99992 2.99999C2.6094 3.39051 2.6094 4.02367 2.99992 4.4142L6.58583 8.00014L2.99996 11.586C2.60944 11.9765 2.60944 12.6097 2.99996 13.0002C3.39048 13.3907 4.02365 13.3907 4.41417 13.0002L8.00005 9.41435L11.586 13.0002C11.9765 13.3907 12.6097 13.3907 13.0002 13.0002C13.3907 12.6097 13.3907 11.9765 13.0002 11.586L9.41426 8.00014L13.0002 4.4142C13.3907 4.02367 13.3907 3.39051 13.0002 2.99999C12.6097 2.60946 11.9765 2.60946 11.586 2.99999L8.00005 6.58592L4.41414 2.99999Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
