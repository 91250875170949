<template>
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.6665 31.5V31.5C44.6665 32.8807 43.5472 34 42.1665 34H39.6665C37.4574 34 35.6665 35.7909 35.6665 38V38.5C35.6665 40.7091 33.8756 42.5 31.6665 42.5H31.1665"
      fill="none"
    />
    <rect
      x="4.9165"
      y="4.75"
      width="39.5"
      height="40.5"
      rx="3.25"
      fill="none"
    />

    <mask id="mask-start-el">
      <rect
        x="8.1665"
        y="17"
        width="6"
        height="6"
        rx="2"
      />
    </mask>
    <rect
      x="8.1665"
      y="17"
      width="6"
      height="6"
      rx="2"
      mask="url(#mask-start-el)"
    />

    <mask
      id="path-4-inside-1_6872_2068"
      fill="none"
    >
      <rect
        x="8.1665"
        y="25"
        width="6"
        height="6"
        rx="2"
      />
    </mask>
    <rect
      x="8.1665"
      y="25"
      width="6"
      height="6"
      rx="2"
      mask="url(#path-4-inside-1_6872_2068)"
    />
    <mask
      id="path-5-inside-2_6872_2068"
      fill="transparent"
    >
      <rect
        x="8.1665"
        y="33"
        width="6"
        height="6"
        rx="2"
      />
    </mask>
    <rect
      x="8.1665"
      y="33"
      width="6"
      height="6"
      rx="2"
      mask="url(#path-5-inside-2_6872_2068)"
    />
    <mask
      id="path-6-inside-3_6872_2068"
      fill="transparent"
    >
      <rect
        x="17.1665"
        y="17"
        width="6"
        height="6"
        rx="2"
      />
    </mask>
    <rect
      x="17.1665"
      y="17"
      width="6"
      height="6"
      rx="2"
      mask="url(#path-6-inside-3_6872_2068)"
    />
    <mask
      id="path-7-inside-4_6872_2068"
      fill="transparent"
    >
      <rect
        x="17.1665"
        y="25"
        width="6"
        height="6"
        rx="2"
      />
    </mask>
    <rect
      x="17.1665"
      y="25"
      width="6"
      height="6"
      rx="2"
      mask="url(#path-7-inside-4_6872_2068)"
    />
    <mask
      id="path-8-inside-5_6872_2068"
      fill="transparent"
    >
      <rect
        x="17.1665"
        y="33"
        width="6"
        height="6"
        rx="2"
      />
    </mask>
    <rect
      x="17.1665"
      y="33"
      width="6"
      height="6"
      rx="2"
      mask="url(#path-8-inside-5_6872_2068)"
    />
    <mask
      id="path-9-inside-6_6872_2068"
      fill="transparent"
    >
      <rect
        x="26.1665"
        y="17"
        width="6"
        height="6"
        rx="2"
      />
    </mask>
    <rect
      x="26.1665"
      y="17"
      width="6"
      height="6"
      rx="2"
      mask="url(#path-9-inside-6_6872_2068)"
    />
    <mask
      id="path-10-inside-7_6872_2068"
      fill="transparent"
    >
      <rect
        x="26.1665"
        y="25"
        width="6"
        height="6"
        rx="2"
      />
    </mask>
    <rect
      x="26.1665"
      y="25"
      width="6"
      height="6"
      rx="2"
      mask="url(#path-10-inside-7_6872_2068)"
    />
    <mask id="mask-end-el">
      <rect
        x="26.1665"
        y="33"
        width="6"
        height="6"
        rx="2"
      />
    </mask>
    <rect
      x="26.1665"
      y="33"
      width="6"
      height="6"
      rx="2"
      mask="url(#mask-end-el)"
    />
    <mask
      id="path-12-inside-9_6872_2068"
      fill="transparent"
    >
      <rect
        x="35.1665"
        y="17"
        width="6"
        height="6"
        rx="2"
      />
    </mask>
    <rect
      x="35.1665"
      y="17"
      width="6"
      height="6"
      rx="2"
      mask="url(#path-12-inside-9_6872_2068)"
    />
    <mask
      id="path-13-inside-10_6872_2068"
      fill="transparent"
    >
      <rect
        x="35.1665"
        y="25"
        width="6"
        height="6"
        rx="2"
      />
    </mask>
    <rect
      x="35.1665"
      y="25"
      width="6"
      height="6"
      rx="2"
      mask="url(#path-13-inside-10_6872_2068)"
    />
    <path
      d="M44.6665 33.5L36.8381 41.3284C36.0879 42.0786 35.0705 42.5 34.0096 42.5H7.1665C5.78579 42.5 4.6665 41.3807 4.6665 40V40"
      fill="none"
    />
    <path d="M12.6665 14H4.6665" />
    <path d="M44.1665 14H15.1665" />
    <path
      d="M14.1665 4.5V3C14.1665 2.17157 13.4949 1.5 12.6665 1.5V1.5C11.8381 1.5 11.1665 2.17157 11.1665 3V7C11.1665 8.10457 12.0619 9 13.1665 9V9"
      fill="none"
    />
    <path
      d="M38.1665 4.5V3C38.1665 2.17157 37.4949 1.5 36.6665 1.5V1.5C35.8381 1.5 35.1665 2.17157 35.1665 3V7C35.1665 8.10457 36.0619 9 37.1665 9V9"
      fill="none"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 2;
}
</style>
