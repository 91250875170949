import 'vuetify/_settings.scss';
import { createVuetify } from 'vuetify/lib/framework.mjs';
import { VuetifyOptions } from 'vuetify';
import Color from 'color';
import { colorBrighter, generateThemeColorList } from '~/app-modules/core/utils/helpers';

// иконки
import ArrowDown from '~/app-modules/core/components/icons/arrow-down.vue';
import ArrowLeft from '~/app-modules/core/components/icons/arrow-left.vue';
import ArrowRight from '~/app-modules/core/components/icons/arrow-right.vue';
import ArrowUp from '~/app-modules/core/components/icons/arrow-up.vue';
import Box from '~/app-modules/core/components/icons/box.vue';
import CalendarEdit from '~/app-modules/core/components/icons/calendar-edit.vue';
import Cart from '~/app-modules/core/components/icons/cart.vue';
import CartAdd from '~/app-modules/core/components/icons/cart-add.vue';
import CartAdded from '~/app-modules/core/components/icons/cart-added.vue';
import CartCheckout from '~/app-modules/core/components/icons/cart-checkout.vue';
import Catalog from '~/app-modules/core/components/icons/catalog.vue';
import Catch from '~/app-modules/core/components/icons/catch.vue';
import Check from '~/app-modules/core/components/icons/check.vue';
import Circle from '~/app-modules/core/components/icons/circle.vue';
import Close from '~/app-modules/core/components/icons/close.vue';
import Cloud from '~/app-modules/core/components/icons/cloud.vue';
import Comment from '~/app-modules/core/components/icons/comment.vue';
import Copy from '~/app-modules/core/components/icons/copy.vue';
import Cylinder from '~/app-modules/core/components/icons/cylinder.vue';
import Delete from '~/app-modules/core/components/icons/delete.vue';
import Documents from '~/app-modules/core/components/icons/documents.vue';
import DragDrop from '~/app-modules/core/components/icons/drag-drop.vue';
import EditPen from '~/app-modules/core/components/icons/edit-pen.vue';
import EmailFile from '~/app-modules/core/components/icons/email-file.vue';
import Error from '~/app-modules/core/components/icons/error.vue';
import Expertise from '~/app-modules/core/components/icons/expertise.vue';
import Feed from '~/app-modules/core/components/icons/feed.vue';
import Feedback from '~/app-modules/core/components/icons/feedback.vue';
import FeedTableExport from '~/app-modules/core/components/icons/feed-table-export.vue';
import FeedTableImport from '~/app-modules/core/components/icons/feed-table-import.vue';
import FeedTableNotExist from '~/app-modules/core/components/icons/feed-table-not-exist.vue';
import File from '~/app-modules/core/components/icons/file.vue';
import FileExport from '~/app-modules/core/components/icons/file-export.vue';
import FileImport from '~/app-modules/core/components/icons/file-import.vue';
import Fish from '~/app-modules/core/components/icons/fish.vue';
import FishAdd from '~/app-modules/core/components/icons/fish-add.vue';
import FishWeight from '~/app-modules/core/components/icons/fish-weight.vue';
import Granules from '~/app-modules/core/components/icons/granules.vue';
import Headphones from '~/app-modules/core/components/icons/headphones.vue';
import Hide from '~/app-modules/core/components/icons/hide.vue';
import Home from '~/app-modules/core/components/icons/home.vue';
import ImageLg from '~/app-modules/core/components/icons/image-lg.vue';
import Logo from '~/app-modules/core/components/icons/logo.vue';
import Logout from '~/app-modules/core/components/icons/logout.vue';
import Math from '~/app-modules/core/components/icons/math.vue';
import Menu from '~/app-modules/core/components/icons/menu.vue';
import NoIcon from '~/app-modules/core/components/icons/no-icon.vue';
import PdfLg from '~/app-modules/core/components/icons/pdf-lg.vue';
import Plus from '~/app-modules/core/components/icons/plus.vue';
import Price from '~/app-modules/core/components/icons/price.vue';
import ProductAvailable from '~/app-modules/core/components/icons/product-available.vue';
import ProductNotAvailable from '~/app-modules/core/components/icons/product-not-available.vue';
import Repeat from '~/app-modules/core/components/icons/repeat.vue';
import ShareLink from '~/app-modules/core/components/icons/share-link.vue';
import Save from '~/app-modules/core/components/icons/save.vue';
import Search from '~/app-modules/core/components/icons/search.vue';
import Show from '~/app-modules/core/components/icons/show.vue';
import Star from '~/app-modules/core/components/icons/star.vue';
import StarOutlined from '~/app-modules/core/components/icons/star-outlined.vue';
import Tech from '~/app-modules/core/components/icons/tech.vue';
import TextLogo from '~/app-modules/core/components/icons/text-logo.vue';
import Tip from '~/app-modules/core/components/icons/tip.vue';
import Waist from '~/app-modules/core/components/icons/waist.vue';
import Warehouse from '~/app-modules/core/components/icons/warehouse.vue';
import Warning from '~/app-modules/core/components/icons/warning.vue';
import Water from '~/app-modules/core/components/icons/water.vue';
import Weight from '~/app-modules/core/components/icons/weight.vue';
import LgCalendar from '~/app-modules/core/components/icons/lg-calendar.vue';
import LgCatch from '~/app-modules/core/components/icons/lg-catch.vue';
import LgFishWeight from '~/app-modules/core/components/icons/lg-fish-weight.vue';
import LgCatchWeight from '~/app-modules/core/components/icons/lg-catch-weight.vue';
import LgStock from '~/app-modules/core/components/icons/lg-stock.vue';
import Wait from '~/app-modules/core/components/icons/wait.vue';

// Цвета дизайна
const mainColors = {
  beton: Color.rgb(50, 50, 60),
  blue: Color.rgb(35, 160, 225),
  green: Color.rgb(60, 145, 115),
  red: Color.rgb(205, 85, 100),
  yellow: Color.rgb(242, 176, 73),
  yellowContrast: Color('#ea9b34'),
};

const vuetifyConfig: VuetifyOptions = {
  ssr: true,
  defaults: {
    VAlert: { variant: 'tonal', VIcon: { size: '12' } },
    VBtn: { rounded: true },
    VCard: { rounded: 'lg', elevation: 12, border: true },
    VCarousel: {
      VBtn: { rounded: undefined },
    },
    VCheckbox: {
      ripple: false,
      falseIcon: '',
      trueIcon: '$check',
    },
    VChip: {
      closeIcon: '$close',
    },
    VTextField: {
      rounded: true,
      variant: 'outlined',
      persistentPlaceholder: true,
      hideDetails: 'auto',
    },
    VTextarea: {
      rounded: true,
      variant: 'outlined',
      persistentPlaceholder: true,
      hideDetails: 'auto',
      autoGrow: false,
      noResize: true,
      rows: 3,
    },
    VAutocomplete: {
      rounded: true,
      variant: 'outlined',
      persistentPlaceholder: true,
      hideDetails: 'auto',
      clearable: true,
      noDataText: 'Нет данных',
    },
    VSelect: {
      rounded: true,
      variant: 'outlined',
      persistentPlaceholder: true,
      hideDetails: 'auto',
      clearable: true,
      noDataText: 'Нет данных',
    },
    VCombobox: {
      rounded: true,
      variant: 'outlined',
      persistentPlaceholder: true,
      hideDetails: 'auto',
      clearable: true,
      noDataText: 'Нет данных',
    },
    VSwitch: {
      flat: true,
      inset: true,
      color: 'blue',
    },
    VMenu: {
      offset: '4',
    },
    VList: {
      rounded: true,
      elevation: '10',
      border: 0,
    },
    VListItem: {
      rounded: true,
      VListItemTitle: { class: 'text-h4 text-beton64' },
      VCheckboxBtn: {
        VIcon: { size: '12', falseIcon: '' },
        ripple: false,
        falseIcon: '',
      },
    },
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: mainColors.blue.hex(),
          secondary: mainColors.yellow.hex(),
          error: mainColors.red.hex(),
          warning: mainColors.yellowContrast.hex(),
          success: mainColors.green.hex(),
          'on-surface': mainColors.beton.hex(), // цвет текста на поверхностях
          'on-primary': colorBrighter(mainColors.beton, 0.03), // цвет текста на поверхностях
          link: mainColors.blue.hex(),
          //
          // Сгенерированные цвета
          ...generateThemeColorList('beton', Color.rgb(50, 50, 60)),
          ...generateThemeColorList('blue', mainColors.blue),
          ...generateThemeColorList('green', mainColors.green),
          ...generateThemeColorList('red', mainColors.red),
          ...generateThemeColorList('yellow', mainColors.yellow),
          'yellow-contrast': mainColors.yellowContrast.hex(),
        },
        variables: {
          // НЕ ПЕРЕНОСИТЬ ЦВЕТА В ФИШВЕБ
          'border-color': '#32323C',
          'border-opacity': 0.2,
          'border-hover-opacity': 0.64,
          'default-emphasis-opacity': 0.84, // прозрачность всех цветов (дополнительная переменная)
          'ripple-emphasis-opacity': 0.12,
          'high-emphasis-opacity': 0.8, // влияет на v-ripple
          // 'medium-emphasis-opacity': 0.6, // влияет на opacity иконок в списках
          'disabled-opacity': 0.6,
          'idle-opacity': 0.04,
          'hover-opacity': 0.1,
          'focus-opacity': 0.1,
          'selected-opacity': 0.1,
          'activated-opacity': 0.1, // активное состояние кнопок и карточек
          'pressed-opacity': 0.1,
          'dragged-opacity': 0.1,
        },
      },
    },
  },
  icons: {
    aliases: {
      'arrow-down': ArrowDown,
      'arrow-left': ArrowLeft,
      'arrow-right': ArrowRight,
      'arrow-up': ArrowUp,
      'calendar-edit': CalendarEdit,
      'cart-checkout': CartCheckout,
      'cart-add': CartAdd,
      'cart-added': CartAdded,
      'drag-drop': DragDrop,
      'edit-pen': EditPen,
      'email-file': EmailFile,
      'feed-table-export': FeedTableExport,
      'feed-table-import': FeedTableImport,
      'feed-table-not-exist': FeedTableNotExist,
      'file-export': FileExport,
      'file-import': FileImport,
      'fish-add': FishAdd,
      'fish-weight': FishWeight,
      'image-lg': ImageLg,
      'pdf-lg': PdfLg,
      'product-available': ProductAvailable,
      'product-not-available': ProductNotAvailable,
      'star-outlined': StarOutlined,
      'share-link': ShareLink,
      'text-logo': TextLogo,

      'lg-calendar': LgCalendar,
      'lg-catch': LgCatch,
      'lg-catch-weight': LgCatchWeight,
      'lg-fish-weight': LgFishWeight,
      'lg-stock': LgStock,

      box: Box,
      cart: Cart,
      catalog: Catalog,
      catch: Catch,
      check: Check,
      circle: Circle,
      cloud: Cloud,
      comment: Comment,
      copy: Copy,
      cylinder: Cylinder,
      documents: Documents,
      expertise: Expertise,
      feed: Feed,
      feedback: Feedback,
      fish: Fish,
      granules: Granules,
      headphones: Headphones,
      hide: Hide,
      home: Home,
      logo: Logo,
      logout: Logout,
      math: Math,
      price: Price,
      repeat: Repeat,
      save: Save,
      search: Search,
      show: Show,
      star: Star,
      tech: Tech,
      tip: Tip,
      waist: Waist,
      wait: Wait,
      warehouse: Warehouse,
      water: Water,
      weight: Weight,

      // стандартные иконки vuetify
      calendar: Logout, // заменить
      cancel: Logout, // заменить
      checkboxIndeterminate: Logout, // заменить
      checkboxOff: NoIcon,
      checkboxOn: Check, // добавить в виде svg
      clear: Close,
      close: Close,
      collapse: Logout, // заменить
      complete: Logout, // заменить
      delete: Delete,
      delimiter: Circle, // заменить
      dropdown: ArrowDown,
      edit: EditPen,
      error: Error,
      expand: ArrowDown,
      file: File,
      first: Logout, // заменить
      info: Warning,
      last: Logout, // заменить
      loading: Logout, // заменить
      menu: Menu,
      minus: Logout, // заменить
      next: ArrowRight, // заменить
      plus: Plus,
      prev: ArrowLeft, // заменить
      radioOff: NoIcon,
      radioOn: Circle,
      ratingEmpty: Logout, // заменить
      ratingFull: Logout, // заменить
      ratingHalf: Logout, // заменить
      sort: Logout, // заменить
      subgroup: ArrowDown,
      success: Check,
      unfold: Logout, // заменить
      warning: Warning,
    },
  },
  display: {
    mobileBreakpoint: 'xs',
    thresholds: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
      xxl: 9999,
    },
  },
};

export const vuetify = createVuetify(vuetifyConfig);

export default defineNuxtPlugin((nuxt) => {
  // Плагин определяет тип устройства телефон|планшет|десктоп,
  // на основе этого определяется ширина фрейма для рендера на ssr
  const userAgentDisplay = useNuxtApp().$userAgentBreakpoint.value;
  const clientWidth = {
    xs: 375,
    md: 1024,
    lg: 1280,
  }[userAgentDisplay];

  nuxt.vueApp.use(createVuetify({ ...vuetifyConfig, ssr: { clientWidth } }));
});
