<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 10 6"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.295629 5.70983C-0.0964002 5.32082 -0.0988454 4.68766 0.290168 4.29563L4.2594 0.295628C4.44621 0.107366 4.70017 0.00102712 4.96539 6.71242e-06C5.2306 -0.00101322 5.48537 0.103369 5.67362 0.290189L9.70439 4.29019C10.0964 4.67921 10.0988 5.31237 9.70981 5.70439C9.32079 6.09641 8.68763 6.09884 8.29561 5.70981L4.97467 2.41422L1.70983 5.70437C1.32082 6.0964 0.687657 6.09884 0.295629 5.70983Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
