<template>
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.75 2.50679L5.70713 4.23427C5.36548 4.80021 4.81016 5.20581 4.16521 5.35766L2.19635 5.82122L3.5205 7.36078C3.95167 7.86209 4.16283 8.51542 4.10783 9.17342L3.93892 11.1941L5.7954 10.4112C6.40584 10.1538 7.09416 10.1538 7.70461 10.4112L9.56108 11.1941L9.39217 9.17342C9.33717 8.51541 9.54834 7.86209 9.9795 7.36078L11.3037 5.82122L9.33479 5.35766C8.68984 5.20581 8.13452 4.80021 7.79287 4.23427L6.75 2.50679ZM7.57077 0.964005C7.19728 0.345332 6.30272 0.345331 5.92923 0.964004L4.42299 3.45904C4.28936 3.6804 4.07254 3.83846 3.82144 3.89758L0.991119 4.56397C0.289309 4.72921 0.0128729 5.58289 0.483854 6.13049L2.38327 8.33889C2.55178 8.53482 2.6346 8.79057 2.61304 9.04847L2.37005 11.9554C2.30979 12.6762 3.03351 13.2038 3.69808 12.9235L6.37822 11.7934C6.616 11.6931 6.884 11.6931 7.12178 11.7934L9.80192 12.9235C10.4665 13.2038 11.1902 12.6762 11.13 11.9554L10.887 9.04847C10.8654 8.79057 10.9482 8.53482 11.1167 8.33889L13.0161 6.13049C13.4871 5.58289 13.2107 4.72921 12.5089 4.56397L9.67856 3.89758C9.42746 3.83846 9.21064 3.6804 9.07701 3.45904L7.57077 0.964005Z"
      fill="#32323C"
      fill-opacity="0.64"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
