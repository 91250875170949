import { HttpConnection } from '~/app-modules/core/clients/http-connection';
import { UiDictionariesDtoSchema } from '~/app-modules/core/clients/dto/ui-dictionaries.dto';
import { NotAuthorizedClient } from '~/app-modules/core/clients/not-authorized-client';

@singleton()
export class UiDictionariesClient extends NotAuthorizedClient {
  constructor(connection: HttpConnection) {
    super(connection);
  }

  async getUiDictionaries() {
    return await this.executeRequest(
      {
        method: 'get',
        url: '/v1/ui-dictionaries',
      },
      UiDictionariesDtoSchema
    );
  }
}
