<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0H4ZM5.21429 2.68878C5.21429 2.30838 4.90591 2 4.52551 2C4.14511 2 3.83673 2.30838 3.83673 2.68878V3.83673H2.68878C2.30838 3.83673 2 4.14511 2 4.52551C2 4.90591 2.30838 5.21429 2.68878 5.21429H3.83673V6.36224C3.83673 6.74265 4.14511 7.05102 4.52551 7.05102C4.90591 7.05102 5.21429 6.74265 5.21429 6.36224V5.21429H6.36225C6.74265 5.21429 7.05102 4.90591 7.05102 4.52551C7.05102 4.14511 6.74265 3.83673 6.36225 3.83673H5.21429V2.68878ZM11.5765 3.83676H9.7398C9.3594 3.83676 9.05103 4.14513 9.05103 4.52553C9.05103 4.90593 9.3594 5.21431 9.7398 5.21431H11.5765H13.4133C13.7937 5.21431 14.102 4.90593 14.102 4.52553C14.102 4.14513 13.7937 3.83676 13.4133 3.83676H11.5765ZM11.1376 9.51019H9.30084C8.92044 9.51019 8.61206 9.81857 8.61206 10.199C8.61206 10.5794 8.92044 10.8877 9.30084 10.8877H11.1376H12.9743C13.3547 10.8877 13.6631 10.5794 13.6631 10.199C13.6631 9.81857 13.3547 9.51019 12.9743 9.51019H11.1376ZM11.1376 11.5765H9.30084C8.92044 11.5765 8.61207 11.8849 8.61207 12.2653C8.61207 12.6457 8.92044 12.954 9.30084 12.954H11.1376H12.9743C13.3547 12.954 13.6631 12.6457 13.6631 12.2653C13.6631 11.8849 13.3547 11.5765 12.9743 11.5765H11.1376ZM6.4103 10.2268C6.67928 9.95786 6.67928 9.52175 6.4103 9.25276C6.14131 8.98378 5.7052 8.98378 5.43622 9.25276L4.46214 10.2268L3.48807 9.25276C3.21909 8.98378 2.78298 8.98378 2.51399 9.25276C2.24501 9.52175 2.24501 9.95786 2.51399 10.2268L3.48807 11.2009L2.51399 12.175C2.24501 12.444 2.24501 12.8801 2.51399 13.1491C2.78298 13.418 3.21909 13.418 3.48807 13.1491L4.46214 12.175L5.43622 13.1491C5.7052 13.418 6.14131 13.418 6.4103 13.1491C6.67928 12.8801 6.67928 12.444 6.4103 12.175L5.43622 11.2009L6.4103 10.2268Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
