<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 11.9343V14.0001H4.06582L11.6794 6.38654L9.61356 4.32072L2 11.9343Z" />
    <path
      d="M10.3207 3.61361L12.3865 5.67943L13 5.06587C13.4034 4.66249 13.4034 4.00848 13 3.60511L12.3949 3.00004C11.9916 2.59667 11.3376 2.59667 10.9342 3.00004L10.3207 3.61361Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 12.0001H13C13.5523 12.0001 14 12.4478 14 13.0001C14 13.5524 13.5523 14.0001 13 14.0001H6L8 12.0001Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
