<template>
  <svg
    height="100%"
    width="100%"
    viewBox="0 0 54 60"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.8223 0.516113H8.22949V59.5H52.831V13.6865M39.8223 0.516113V13.6865H52.831M39.8223 0.516113L52.831 13.6865"
      stroke="#999999"
    />
    <rect
      x="0.167969"
      y="6.85156"
      width="27.4482"
      height="11.4761"
      rx="2"
      fill="#FD0402"
    />
    <path
      d="M9.7639 11.0988C9.7639 11.8223 9.51622 12.3795 9.02087 12.7706C8.52878 13.1584 7.82323 13.3523 6.90423 13.3523H6.06344V16.1631H5.23242V9.01636H7.0851C8.87097 9.01636 9.7639 9.7105 9.7639 11.0988ZM6.06344 12.6386H6.81135C7.54786 12.6386 8.08069 12.5197 8.40983 12.2818C8.73898 12.0439 8.90355 11.6626 8.90355 11.1379C8.90355 10.6654 8.74876 10.3134 8.43916 10.082C8.12957 9.85063 7.64725 9.73494 6.99222 9.73494H6.06344V12.6386Z"
      fill="white"
    />
    <path
      d="M16.9693 12.5213C16.9693 13.701 16.6483 14.6037 16.0063 15.2294C15.3675 15.8519 14.4469 16.1631 13.2444 16.1631H11.2646V9.01636H13.4546C14.5659 9.01636 15.4295 9.32432 16.0454 9.94025C16.6613 10.5562 16.9693 11.4165 16.9693 12.5213ZM16.0894 12.5506C16.0894 11.6186 15.8548 10.9163 15.3855 10.4437C14.9195 9.97121 14.2253 9.73494 13.303 9.73494H12.0956V15.4445H13.1075C14.0982 15.4445 14.8429 15.2017 15.3415 14.7161C15.8401 14.2273 16.0894 13.5055 16.0894 12.5506Z"
      fill="white"
    />
    <path
      d="M19.3988 16.1631H18.5678V9.01636H22.5518V9.75449H19.3988V12.3697H22.3611V13.1079H19.3988V16.1631Z"
      fill="white"
    />
    <path
      d="M25.714 42.666C28.3082 39.0577 34.1814 29.7437 35.6696 25.1645C37.724 18.8434 35.1688 15.973 32.8252 20.6212C30.4547 25.3225 33.734 38.6363 35.9858 42.666C38.2377 46.6957 42.0699 49.9353 42.8205 47.2883C43.5711 44.6414 38.8303 41.5994 33.8525 41.4809C28.8746 41.3623 18.9189 43.6537 16.1139 46.6167C13.309 49.5797 18.2552 54.7007 25.714 42.666Z"
      stroke="#FD0402"
    />
  </svg>
</template>
<script setup lang="ts"></script>
